<template>
    <Modal ref="modal"
           :hideFooter="true"
           title="חיפוש לקוחות ואירועים">

        <template v-slot:body>

            <input placeholder="חיפוש"
                   ref="findCustomerModalTextInput"
                   class="form-control"
                   style="margin:15px auto 15px auto;display:block;"
                   v-model="searchTerm">



            <div>

                <div v-for="searchResult in searchResults"
                     class="autocompleteResult"
                     @click="chooseCustomer(searchResult)">

                    <span>
                        {{ searchResult.contactNames }}
                    </span>

                    <span v-if="searchResult.eventName">
                        -
                        {{ searchResult.eventName }}
                    </span>

                    <span style="float:left;margin-right:5px;color:rgb(119,119,119)">
                        {{ searchResult.eventDate }}
                    </span>

                    <span style="float:left;color:rgb(119,119,119)">
                        {{ searchResult.eventClass }}
                    </span>
                    





                </div>

            </div>

        </template>

    </Modal>
</template>
<script>
import axiosV2 from "../../core/http/axios_api_v2";
import { debounce } from "../../helpers/interaction";

export default {
    name: "FindCustomerModal",
    data() {
        return {
            searchTerm: "",
            searchResults: [],
            randomValueToWaitFor: Math.random(),

            callbackWhenFound: null,

        };
    },
    mounted() {
        this.search();
    },
    watch: {
        searchTerm() {
            this.searchResults = [];
            debounce(this.search, 800)();
        }
    },
    methods: {
        chooseCustomer(customer) {
            // debugger;
            this.hide();
            this.$root.$emit('hideQuickCalendar');
            // this.$emit('found',customer);
            this.callbackWhenFound(customer);       // we call the callback with a new field on the customer - chosenEvent
        },
        show(callback) {
            // debugger;
            this.$refs.modal.show();
            this.callbackWhenFound = callback;
            this.searchTerm = '';

            setTimeout(() => { this.$refs.findCustomerModalTextInput.focus() }, 300)

        },
        hide() {
            this.$refs.modal.hide()
        },
        search() {

            if (!this.searchTerm || this.searchTerm.length<2) {
                this.checkSearchResults({results:this.$store.state.emptyAutocomplete})
                return;
            }


            // axiosV2(`/customers/autocomplete?term=${this.searchTerm}&random=${this.randomValueToWaitFor}`).then(result => this.checkSearchResults(result.data));

            axiosV2(`/search-results/?term=${this.searchTerm}&random=${this.randomValueToWaitFor}`).then(result => this.checkSearchResults(result.data));
        },
        checkSearchResults(searchResult) {

            if (searchResult.random != this.randomValueToWaitFor && this.searchTerm)
                return;


            let resultsToShow = [];

            for (let i = 0; i < searchResult.results.length; i++) {



                if (!searchResult.results[i]?.events?.length) {
                    resultsToShow.push(searchResult.results[i]);
                    continue;
                }



                if (searchResult.results[i].events.length == 1) {
                    searchResult.results[i].chosenEvent = searchResult.results[i].events[0];
                    resultsToShow.push(searchResult.results[i]);
                    continue;
                }


                for (let j = 0; j < searchResult.results[i].events.length; j++) {
                    let newResult = deepCopy(searchResult.results[i]);
                    newResult.chosenEvent = searchResult.results[i].events[j];
                    resultsToShow.push(newResult);
                }


            }

            this.searchResults = resultsToShow;

        }
    },
    components: {}
}
</script>