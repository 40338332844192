<template>
    <div :class="{ card }">



        <div :class="{ 'card-header': card }">
            <h5 v-if="!hideTitle"
                style="border-bottom:none">
                קביעת פגישה
            </h5>
        </div>

        <div :class="{ 'card-body': card }">


            <div v-if="actual_summaryView"
                 class="planupMeetingFormSummaryView">



                <div class="mb-2">
                    שם הפגישה:
                    {{ meeting.title }}
                </div>


                <div @click="actual_summaryView = false; $emit('switchToSmallModal')"
                     style="color:#ab8ce4;cursor:pointer;margin-bottom:10px;"
                     v-if="actual_summaryView">

                    ערוך פרטי פגישה
                </div>
                <div class="row"
                     style="margin-bottom:10px;">

                    <div class="col-4">

                        <i class="fa-regular fa-calendar"></i>
                        &nbsp;
                        יום
                        {{ $globals.dayOfWeekFromDate(meeting.date) }}

                        {{ $globals.niceDate(meeting.date) }}
                        בשעה
                        {{ meeting.time }}

                    </div>


                    <div class="col-4">

                        <i class="fa-solid fa-location-dot"></i>
                        &nbsp;
                        {{ meeting.place && meeting.place.name }}

                    </div>



                    <div class="col-4">

                        <i class="fa-solid fa-users"></i>
                        &nbsp;
                        <span v-for="crewMember in meeting.crewMembers">
                            {{ crewMember.name }},
                        </span>

                    </div>

                </div>



                <div>
                    <i class="fa-regular fa-note-sticky"></i>
                    &nbsp;
                    {{ meeting.comments }}
                </div>





                <hr>
                <br>

                <h5 style="float:right;border-bottom:none">
                    סיכום פגישה
                </h5>
                <div style="margin-bottom:30px;">

                    <div class="col-4"
                         style="float:left">


                        <Multiselect style="z-index:200;position:relative;"
                                     selectedLabel=""
                                     deselect-label=""
                                     :allow-empty="false"
                                     label="name"
                                     track-by="id"
                                     placeholder="בחרו תבנית לסיכום פגישה"
                                     selectLabel=""
                                     v-model="summaryTemplate"
                                     :options="$store.state.documents.meetingSummaryTemplates">
                        </Multiselect>
                    </div>


                    <div style="clear:both"></div>

                    <EditDocument class="planupMeetingFormDocumentEditor"
                                  v-if="meeting.summary"
                                  :uniqueForEvent="true"
                                  :inModal="true"
                                  ref="editSummary"
                                  :key="summaryDocument.content"
                                  :document="summaryDocument"></EditDocument>



                </div>




            </div>


            <div class="row"
                 v-if="!actual_summaryView">




                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label">
                            {{ $t('planupMeetingTypes') }}
                            <span style="color:#ab8ce4;cursor:pointer"
                                  @click="$refs.QuickEditPlanupMeetingTypes.show()">
                                ניהול מהיר
                            </span>
                        </label>

                        <QuickEdit ref="QuickEditPlanupMeetingTypes"
                                   :title="$t('QuickEditPlanupMeetingTypes')"
                                   controller="planup-meeting-types"
                                   listName="planupMeetingTypes">
                        </QuickEdit>
                        <Multiselect selectedLabel=""
                                     deselect-label=""
                                     label="name"
                                     track-by="id"
                                     placeholder=""
                                     @tag="$handlers.listsHandler.createListItem($event, 'planupMeetingTypes', 'planup-meeting-types', meeting, 'planupMeetingType')"
                                     selectLabel=""
                                     tagPlaceholder="הקישו אנטר ליצירת  סוג פגישה חדש"
                                     :taggable="true"
                                     v-model="meeting.meetingType"
                                     :options="$store.state.planupMeetingTypes.filter(x => !x.hidden)">
                        </Multiselect>
                    </div>
                </div>









                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label">
                            שם הפגישה
                        </label>
                        <input v-model="meeting.title"
                               class="form-control">

                        <span class="formErrorText"
                              v-if="meeting.errors">
                            {{ meeting.errors.title }}
                        </span>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="mb-3">
                        <label class="form-label">
                            תאריך
                        </label>
                        <div class="mb-3">
                            <EventDatePicker :tabs="[1]"
                                             :placeHolder="$globals.niceDate(meeting.date)"
                                             @setTime="meeting.time = $event"
                                             @singleDate="meeting.date = $event"></EventDatePicker>

                            <span class="formErrorText"
                                  v-if="meeting.errors">
                                {{ meeting.errors.date }}
                            </span>
                        </div>

                    </div>
                </div>

                <div class="col-md-2">
                    <div class="mb-3">
                        <label class="form-label">
                            שעת התחלה
                        </label>
                        <div class="mb-3">
                            <!-- 
                            <input type="time"
                                   class="form-control"
                                   v-model="meeting.time"
                                   style="text-align:right"> -->


                            <Multiselect selectedLabel=""
                                         deselect-label=""
                                         placeholder=""
                                         @tag="meeting.time = $event"
                                         selectLabel=""
                                         tagPlaceholder=""
                                         :taggable="true"
                                         v-model="meeting.time"
                                         :options="$store.state.timesArray">
                            </Multiselect>


                            <!-- <flatPickr ref="meetingTimePicker"
                                       :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true }"
                                       v-model="meeting.time"></flatPickr> -->
                        </div>

                    </div>
                </div>

                <div class="col-md-2">
                    <div class="mb-3">
                        <label class="form-label">
                            שעת סיום
                        </label>
                        <div class="mb-3">
                            <!-- 
                            <input type="time"
                                   class="form-control"
                                   v-model="meeting.time"
                                   style="text-align:right"> -->


                            <Multiselect selectedLabel=""
                                         deselect-label=""
                                         placeholder=""
                                         @tag="meeting.endTime = $event"
                                         selectLabel=""
                                         tagPlaceholder=""
                                         :taggable="true"
                                         v-model="meeting.endTime"
                                         :options="$store.state.timesArray">
                            </Multiselect>


                            <!-- <flatPickr ref="meetingTimePicker"
                                       :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true }"
                                       v-model="meeting.time"></flatPickr> -->
                        </div>

                    </div>
                </div>

                <div class="col-md-3">
                    <div class="mb-3">
                        <label class="form-label">
                            מקום הפגישה
                            <span style="color:#ab8ce4;cursor:pointer"
                                  @click="$refs.QuickEditMeetingPlaces.show()">
                                ניהול מהיר
                            </span>
                        </label>
                        <QuickEdit ref="QuickEditMeetingPlaces"
                                   :title="$t('QuickEditMeetingPlaces')"
                                   controller="meeting-places"
                                   listName="meetingPlaces">

                        </QuickEdit>
                        <Multiselect selectedLabel=""
                                     deselect-label=""
                                     :allow-empty="false"
                                     label="name"
                                     track-by="id"
                                     placeholder=""
                                     @tag="$handlers.listsHandler.createListItem($event, 'meetingPlaces', 'meeting-places', meeting, 'place')"
                                     selectLabel=""
                                     tagPlaceholder="הקישו אנטר ליצירת מקום פגישה חדש"
                                     :taggable="true"
                                     v-model="meeting.place"
                                     :options="$store.state.meetingPlaces.filter(x => !x.hidden)">
                        </Multiselect>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="mb-3">
                        <label class="form-label">
                            {{ $t('crewMembers') }}
                            <span style="color:#ab8ce4;cursor:pointer"
                                  @click="$refs.QuickEditCrewMembers.show()">
                                ניהול מהיר
                            </span>
                        </label>

                        <!-- <QuickEdit ref="QuickEditCrewMembers"
                                   :title="$t('QuickEditCrewMembers')"
                                   controller="crew-members"
                                   listName="crewMembers">
                        </QuickEdit> -->


                        <QuickSuperEdit @updateList=""
                                        ref="QuickEditCrewMembers"
                                        :title="$t('QuickEditCrewMembers')"
                                        listName="crewMembers"
                                        controller="crew-members"
                                        :bulkSaveFilterFunction="$handlers.listsHandler.bulkSave_filterCrewMembers"
                                        :fields="$store.state.fieldsForQuickSuperEditCrewMembers">
                        </QuickSuperEdit>


                        <Multiselect selectedLabel=""
                                     deselect-label=""
                                     label="name"
                                     track-by="id"
                                     :multiple="true"
                                     placeholder=""
                                     @tag="$handlers.listsHandler.createListItem($event, 'crewMembers', 'crew-members', meeting, 'crewMembers', true)"
                                     selectLabel=""
                                     tagPlaceholder=""
                                     :taggable="true"
                                     v-model="meeting.crewMembers"
                                     :options="$store.state.crewMembers.filter(x => !x.hidden)">
                        </Multiselect>
                    </div>
                </div>



                <div class="col-12">
                    <div class="mb-3">
                        <div class="mb-2">
                            הערות
                        </div>

                        <textarea v-model="meeting.comments"
                                  style="height:200px;"
                                  class="form-control"></textarea>
                    </div>
                </div>




            </div>



            <div v-if="actual_summaryView">
                <label style="user-select: none;cursor:pointer"
                       @click="createTask = !createTask">


                    <span style="position:relative;top:1px;"
                          class="planup-checkbox planup-checkbox-purple"
                          :class="{ checked: createTask }">
                        <i class="fa fa-check"></i>
                    </span>


                    צור משימה למעקב
                </label>
            </div>



            <div style="float:right">
                <br>
                <span style="cursor:pointer"
                      v-if="meeting.id"
                      @click="deleteMeeting">
                    מחק הפגישה
                </span>
            </div>

            <div class="pull-right">


                <button @click="saveMeeting(true)"
                        class="btn btn-primary"
                        v-if="customer">

                    שמור ועבור לפרטי האירוע
                </button>


                &nbsp;


                <button @click="saveMeeting()"
                        class="btn btn-primary"
                        v-if="actual_summaryView">

                    שמור
                </button>



                <button v-if="!actual_summaryView"
                        class="btn btn-primary"
                        @click="saveMeeting()">
                    {{ saveButtonTextIsContinue ? 'המשך' : 'שמור' }}
                </button>







            </div>

        </div>

    </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import QuickEdit from '../QuickEdit/QuickEdit.vue';
import Multiselect from 'vue-multiselect';
import EventDatePicker from '../EventDatePicker/EventDatePicker.vue';
import EditDocument from '../Documents/EditDocument';
import DocumentsHandler from "../../modules/DocumentsHandler";
import QuickSuperEdit from '../QuickEdit/QuickSuperEdit.vue';

export default {
    name: 'PlanupMeetingForm',
    data() {


        // let contactMails = [];

        // for (let i = 0; i < this?.meeting?.customer?.contacts?.length; i++) {


        //     if (!this?.meeting.customer.contacts[i].email)
        //         continue;


        //     contactMails.push({
        //         email: this?.meeting.customer.contacts[i].email,
        //         name: this?.meeting.customer.contacts[i].firstName,
        //     })
        //     this.meeting.notifyMailAddress.push({
        //         email: this?.meeting.customer.contacts[i].email,
        //         name: this?.meeting.customer.contacts[i].firstName,
        //     })
        // }

        return {

            meetingEndTimeObject: null,

            createTask: false,

            // contactMails,

            summaryTemplate: null,

            loadingSummaryFromTemplate: false,

            actual_summaryView: this.summaryView,
            summaryDocument: null,

            dontShowPopupForChangeSummaryIfEqualsThis: null,

        }
    },

    watch: {


        'meeting.time': function(newVal,endVal) {

            if (!newVal)
                return;

            if (!this.meeting.endTime) {
                let [hours, minutes] = newVal.split(':');
                if (hours==23)
                    return;
                hours = +hours+1;
                this.meeting.endTime = hours + ':' + minutes;

            }

        },


        'meeting.meetingType': function (newVal) {

            if (!newVal)
                return;

            if (!this.customer) {
                this.meeting.title = newVal.name;
                return;
            }


            this.meeting.title = newVal.name + ' עם ' + this.customer.contacts[0].firstName;

            if (this.customer.contacts[1]?.firstName)
                this.meeting.title += ' ' + 'ו' + this.customer.contacts[1].firstName;

        },

        summaryTemplate(newVal, oldVal) {


            //null means we are just not displaying the form with a new meeting loaded into it
            if (newVal == null)
                return;


            //if we are "changed back" after a cancel, without this line it loops
            if (newVal == this.dontShowPopupForChangeSummaryIfEqualsThis)
                return;

            this.$swal({
                title: 'בטוחים שרוצים להחליף תבנית?',
                // text: 'כל השינויים שעשיתם יעלמו.',
                confirmButtonText: 'כן',
                cancelButtonText: 'לא',
                confirmButtonColor: '#ab8ce4',
                showCancelButton: true,

            }).then(() => {

                DocumentsHandler.getDocument(newVal?.id, summaryTemplateDocument => {

                    // this is very important, when we pull a new document template, we don't want to override existing documents when saving, so remove id
                    DocumentsHandler.templateToDocument(summaryTemplateDocument);


                    this.summaryDocument = summaryTemplateDocument;


                    this.dontShowPopupForChangeSummaryIfEqualsThis = null;


                })

            }).catch(() => {
                this.summaryTemplate = oldVal;
                this.dontShowPopupForChangeSummaryIfEqualsThis = oldVal;
            })


        },

        meeting() {

            this.createTask = false;

            this.summaryTemplate = null;
            this.summaryDocument = this.meeting?.summary?.name ? this.meeting?.summary : DocumentsHandler.generateLocalEmptyDocument();
        },
    },


    props: ['meeting', 'hideTitle', 'card', 'saveButtonTextIsContinue', 'summaryView', 'customer'],
    methods: {


        deleteMeeting() {

            this.$swal({
                title: 'האם למחוק את הפגישה?',
                confirmButtonText: 'כן',
                cancelButtonText: 'לא',
                confirmButtonColor: '#ab8ce4',
                showCancelButton: true,
            })
                .then(() => {
                    this.$handlers.meetingHandler.deleteMeeting(this.meeting.id)
                }).then(() => {
                    window.location.reload();
                })
        },


        addNotifyMailAddress(email) {

            this.meeting.notifyMailAddress.push({
                email,
                name: email
            })



        },

        resetSummaryViewToPropValue() {
            this.actual_summaryView = this.summaryView
        },

        saveMeeting(goToEventTab) {


            if (!this.$handlers.meetingHandler.validateMeeting(this, this.meeting))
                return false;

            if (this.actual_summaryView) {
                this.meeting.summary.content = this.$refs?.editSummary?.getDocumentAfterEdits();
                this.meeting.summary.groupName = 'meetingSummary'
                this.meeting.summary.name = 'סיכום פגישה ' + this.meeting.title;




                if (this.createTask) {

                    let task = this.$handlers.tasksHandler.generateLocalEmptyTask();
                    task.description = 'מעקב: \n\n' + this.meeting.summary.content;
                    task.name = 'מעקב פגישה: ' + this.meeting.title;

                    task.customer = this.meeting.customer;
                    planup.$root.$emit('openTaskModal', task)


                }


            }


            if (this.customer?.contacts?.length || this.meeting?.crewMembers?.length){
                
                this.$root.$emit('showSummonModal', {
                contacts: this.customer?.contacts,
                crewMembers: this.meeting?.crewMembers,
                title: this.meeting?.title,
                location: this.meeting?.place?.name,
                date: this.meeting?.date,
                time: this.meeting?.time

            });
            }

            

            this.$emit('saveMeeting');


            if (!this.customer)
                return;

            this.$globals.findComponent('CustomerPage').setActiveTab(goToEventTab ? 'customerPageQuotationTab' : 'customerPageGeneralInfoTab');
        },

    },
    components: { Multiselect, QuickEdit, EventDatePicker, flatPickr, EditDocument, QuickSuperEdit }
}
</script>
<style>
.planupMeetingFormSummaryView .ql-container {
    aspect-ratio: unset !important;
}

.planupMeetingFormSummaryView .quillEditorToolbarWrapper {
    width: 100% !important
}

.planupMeetingFormSummaryView i {
    color: rgb(119, 119, 119);
}


.planupMeetingFormSummaryView .ql-container.ql-container {
    margin-top: 0 !important
}


.planupMeetingFormSummaryView .ql-container.ql-container {
    margin-top: 0 !important
}

.planupMeetingFormSummaryView .ql-container.ql-snow.ql-container.ql-snow {

    padding-top: 30px !important;
    padding-bottom: 30px !important;
    min-height: 200px;
    box-sizing: content-box;
}

.ql-blank {
    min-height: 200px;
}

.planupMeetingFormDocumentEditor .ck-content {
    width: 100% !important;
    padding-top: 40px !important;
}

.planupMeetingFormDocumentEditor {
    position: relative;
    top: -5px;
}
</style>