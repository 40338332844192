

export const getServerName = () => {

    const devServerHostList = ['localhost'];
    const testServerHostList = ['testup.mit4mit.co.il'];
    const liveServerHostList = ['planup.co.il','www.planup.co.il'];

    if (devServerHostList.includes(window.location.hostname))
        return 'DevUp';


    if (testServerHostList.includes(window.location.hostname))
        return 'TestUp';


    if (liveServerHostList.includes(window.location.hostname))
        return 'PlanUp';
    
}


export const getServerSettings = () => {

    switch (getServerName()){

        case 'DevUp':

            return {
                APP_URL: "http://localhost:8080/",
                API_V2_URL: "http://localhost:2053/apiv2/",
                MEDIA_URL: "http://localhost/public/",
                SIGN_DOCUMENT_URL : "http://localhost/signDocument.php",
                SHOW_DOCUMENT_URL : "http://localhost/showDocument.php",
                CREATE_PDF_URL : "http://localhost/createPdf.php",
                SOCKET_URL: `http://localhost:4000/`,

            };

        case 'PlanUp':

        return {
            APP_URL: "https://planup.co.il/",
            API_V2_URL: window._apiUrl ? window._apiUrl : "https://planup.co.il/apiv2/",
            SOCKET_URL: `https://planup.co.il:4000/`,
            MEDIA_URL: "https://www.planup.co.il/not-in-use",
            SIGN_DOCUMENT_URL : "https://planup.co.il/p2/docs/signDocument.php",
            SHOW_DOCUMENT_URL : "https://planup.co.il/p2/docs/showDocument.php",
            CREATE_PDF_URL : "https://planup.co.il/p2/docs/createPdf.php",
        };


    }

}


export const useMultiSchemaTenancy = () => {

    return true;
}

export const isPlanup = () => {

    return true;
}



class Config {
    
    static hostname = isPlanup() ? 'planup.co.il' : 'seatup.co.il';

    static SETTINGS = getServerSettings();

    static API_ROUTES = {
        'login': 'login',
        'isLogged': 'isLogged',
        'dashboard': 'dashboard',
        'users': 'users',
        'appointments': 'appointments',
        'quotations': 'quotations',
        'contact': 'contact',
        'events': 'events',
        'guests': 'guests',
        'upload': 'fileUpload',
        'halls': 'halls',
        'tables': 'tables',
        'reports': 'reports',
        'presets': 'presets',
        'settings': 'appSettings',
        'calendar': 'calendar',
        'equipments': 'equipment',
        'banners': 'banners'
    }
    
    static DEFAULT_API_SETTINGS = { headers: { 'Content-Type': 'text/json' }, withCredentials: true };

    static DEFAULT_API_V2_SETTINGS() {
        return {
            baseURL: Config.SETTINGS.API_V2_URL,
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    static GUEST_STATUS = {
        'notResponded': 0,
        'coming': 1,
        'maybe': 2,
        'notComing': 3,
    }

    static html2CanvasConfig = {
        "type": "dataURL",
        // "allowTaint": true,
        // "foreignObjectRendering": false,
        // "logging": false,
        "useCORS": true,
        // "crossOrigin": 'anonymous'
    }

};

export default Config
