export default {
    name: 'ErrorHandler',

    validateNotEmpty(component) {




        // component.$refs.customerContacts.$refs.customerContactFields[0].validationErrors.firstName = 'First Name is required';


    },


    addToolTip(inputElement, errorText) {


        const rect = inputElement.getBoundingClientRect();

        if (!rect.left && !rect.top) {
            return;
        }

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


        const tooltip = document.createElement('div');
        tooltip.style.top = `${rect.top + scrollTop - tooltip.offsetHeight - 5}px`;
        tooltip.style.left = `${rect.left + scrollTop}px`;



        tooltip.style.position = 'absolute';
        tooltip.style.padding = '5px';

        tooltip.style.background = 'red';
        tooltip.style.color = 'white';
        tooltip.style.borderRadius = '5px';

        tooltip.textContent = errorText;


        console.log(tooltip);


        document.body.appendChild(tooltip);
    }
}