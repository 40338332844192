<template>
    <div>




        <div class="quillEditorToolbarWrapper"
             style="position:sticky;top:90px;width:1120px;margin:0 auto;z-index:99">
            <div style="position:relative;top:2px">


                <div class="card"
                     v-if="!inModal"
                     style="position:absolute;top:-4px;width:1120px;left: 1px;height:42px;">


                    <div>

                        <input v-if="!uniqueForEvent"
                               id="documentNameInput"
                               style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:128px;position:relative;float:left;left:0;top:5px;border:none;border-right:solid 1px #ccced1;border-radius:0;padding-top:3px;padding-bottom:3px"
                               class="form-control"
                               v-model="document.name"
                               @focus="hideToolbar = true;"
                               @blur="hideToolbar = false; updateName()">


                        <button v-show="hideToolbar"
                                v-if="!uniqueForEvent"
                                class="btn btn-xs btn-primary"
                                style="position:relative;font-size:14px;top:13px;">
                            שנה שם
                        </button>





                        <button v-show="!hideToolbar"
                                v-if="!uniqueForEvent"
                                class="btn btn-xs btn-primary"
                                @click="saveDocument()"
                                style="position:relative;float:right;right:12px;top:9px;font-size:14px;padding-left:12px;padding-right:12px">
                            שמור
                        </button>

                        <button v-show="!hideToolbar"
                                v-if="!uniqueForEvent"
                                class="btn btn-xs btn-primary"
                                @click="printDocument()"
                                style="position:relative;float:right;right:22px;top:9px;font-size:14px;padding-left:12px;padding-right:12px">
                            הדפס
                        </button>


                        <div style="position:absolute;bottom:-120px;width:215px;padding:5px;;left:0;background:white;"
                             class="card">

                            <div>



                            


                                <Multiselect label="name"
                                             style="width:120px;display:inline-block;"
                                             v-model="groupName"
                                             :allow-empty="true"
                                             deselectLabel=" "
                                             selectedLabel=" "
                                             placeholder="סוג מסמך"
                                             track-by="groupName"
                                             selectLabel=" "
                                             :options="$handlers.documentsHandler.documentGroups"></Multiselect>


                            </div>

                        </div>


                        <div style="position:absolute;bottom:-180px;width:215px;padding:5px;;left:0;background:white;"
                             class="card">

                            <div>




                                <Multiselect label="name"
                                             style="width:120px;display:inline-block;"
                                             v-model="eventClass"
                                             :allow-empty="true"
                                             deselectLabel=" "
                                             selectedLabel=" "
                                             placeholder="סוג אירוע"
                                             track-by="id"
                                             selectLabel=" "
                                             :options="$store.state.eventClasses"></Multiselect>


                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

        <CkEditor ref="ckEditor"
                  @ready="editorIsReady()"
                  :class="{ hideToolbar, inModal }"></CkEditor>

    </div>
</template>
<script>

import Multiselect from "vue-multiselect";
import DocumentsHandler from "../../modules/DocumentsHandler";
import CkEditor from "./CkEditor.vue";

export default {
    name: 'EditDocument',
    methods: {

        printDocument() {

            document.querySelector('#showOnlyForPrint').innerHTML = this.$refs.ckEditor.getData();

            print();

        },


        getDocumentAfterEdits() {
            return this.$refs.ckEditor.getData();
        },


        updateName() {


            if (this.document.name == this.chosenDocumentOriginalNameBeforeEdits)
                return;

            this.$swal({
                title: 'האם לעדכן את שם המסמך?',
                text: 'המסמך ' + '"' + this.chosenDocumentOriginalNameBeforeEdits + '" יקרא מעכשיו "' + this.document.name + '".',
                confirmButtonText: 'שמור',
                cancelButtonText: 'ביטול',
                confirmButtonColor: '#ab8ce4',
                showCancelButton: true,

            }).then(result => {

                DocumentsHandler.updateName(this.document);
                this.chosenDocumentOriginalNameBeforeEdits = this.document.name;

            }).catch(error => {
                this.document.name = this.chosenDocumentOriginalNameBeforeEdits;
            })

        },


        saveDocument() {

            this.$swal({
                title: 'האם לשמור שינויים?',
                text: 'השינויים החדשים יחליפו את התוכן הקיים במסמך "' + this.document.name + '".',
                confirmButtonText: 'שמור',
                cancelButtonText: 'ביטול',
                confirmButtonColor: '#ab8ce4',
                showCancelButton: true,

            }).then((result) => {


                this.$html2canvas(this.$el.querySelector('.ck-content'), { scale: 0.4 }).then(

                    canvas => {

                        this.document.previewImage = canvas.toDataURL();
                        this.document.content = this.$refs.ckEditor.getData();

                        this.document.isTemplate = true;

                        DocumentsHandler.saveDocument(this.document).then((document)=>{

                            this.$router.push(`/document/?id=${document.id}`);

                        })

                    
                    }

                )


            }).catch(error => {

            })

        },


        editorIsReady() {

            if (this.document?.content) {

                this.$refs.ckEditor.setData(this.document.content);

            }

        }

    },

    props: ['document', 'uniqueForEvent', 'inModal', 'startMinimized'],

    data() {

        return {

            eventClass: null,
            groupName: null,
            chosenDocumentOriginalNameBeforeEdits: this.document?.name,
            hideToolbar: false,
        }
    },

    watch: {

        document: function () {


            this.chosenDocumentOriginalNameBeforeEdits = this.document?.name;

            if (this.document.content.length)
                this.$refs.ckEditor?.setData(this.document?.content)

            this.groupName = this.$handlers.documentsHandler.documentGroups.find(x => x.groupName == this.document.groupName);
            this.eventClass = this.$store.state.eventClasses.find(x => x.id == this.document.eventClassId);


        },

        groupName(newVal) {
            this.document.groupName = newVal?.groupName;
        },


        eventClass(newVal){

            newVal && (this.document.eventClassId = newVal.id);
        }

    },

    mounted() {



    },

    components: { CkEditor, Multiselect }

}
</script>
<style>
.inModal .ck-editor__top {
    width: calc(100% - 2px) !important;
    padding: 0 !important;
    border: solid 1px #ced4da;
    top: 40px !important;
    position: relative !important;
    margin-bottom: 0 !important;
    border-radius: 6px 6px 0 0 !important;
    margin-top: 0 !important;
    border-right: none !important;
    border-left: none !important;
}

.inModal .ck-toolbar {
    border-radius: 6px !important;
}

.inModal .ck-editor__top button {
    /* margin-right: 1px !important; */
    margin-right: unset !important;
    flex: 1 !important
}

.inModal .ck-toolbar__separator {
    margin-right: unset !important;
    flex: 0 !important
}

.inModal .ck-content {
    aspect-ratio: unset !important;
    height: 250px !important;
    margin-top: 0 !important;
    background: rgb(246, 247, 251) !important;
}
</style>