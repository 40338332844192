let MenuForAdmin = [

    {
        name: 'addUser',
        route: '/users/add',
        service: 1
     },

];

export default MenuForAdmin;