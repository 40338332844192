///////////
///////////
///////////
///////////
///////////
///////////
///////////
///////////
///////////
///////////

// document groups:

// meetingSummary
// terms

///////////
///////////
///////////
///////////
///////////
///////////
///////////
///////////















import axiosV2, { apiPost } from '../core/http/axios_api_v2';

export default {




    documentFilters: [
        {
            name: 'כל התבניות',
            templates: false,
            groupName: '',
            all: true
        },
        {
            name: 'תבניות סיכום פגישה',
            templates: true,
            groupName: 'meetingSummary'
        },
        {
            name: 'תבניות תנאי הסכם',
            templates: true,
            groupName: 'terms'
        },
    ],

    documentGroups: [
        {
            name: 'סיכום פגישה',
            groupName: 'meetingSummary'
        },
        {
            name: 'תנאי הסכם',
            groupName: 'terms'
        }
    ],





    generateLocalEmptyDocument() {
        return {
            content: '',
            name: 'מסמך חדש',
            groupName: null,
            isTemplate: false,
            id: null,
        }
    },

    async saveDocument(documentData) {

        return apiPost('/documents', documentData).then(
            savedDocument => {

                documentData.id = savedDocument.id;

                this.updateDocumentInStore(documentData);
                
                return documentData;
            }
        );
    },


    updateDocumentInStore(document){

        let arrayToSaveDoc = null;

        switch (document.groupName) {

            case 'meetingSummary':
                    arrayToSaveDoc = window.planup.$store.state.documents.meetingSummaryTemplates;   
                break;

            case 'terms':
                arrayToSaveDoc = window.planup.$store.state.documents.termsTemplates;
                break;


            default:
                return;
        }


        let index = arrayToSaveDoc.findIndex(doc => doc.id === document.id);
        if (index === -1) {
            arrayToSaveDoc.push(document);
        } else {
            arrayToSaveDoc[index] = document;
        }



    },



    templateToDocument(template) {
        delete template.id;
        delete template.isTemplate;
        delete template.name;
    },


    async getDocument(id, callback) {

        if (!id)
            return this.generateLocalEmptyDocument();

        return axiosV2("/documents/" + id).then(

            response => {

                if (callback) {
                    callback(response.data)
                }

                return response.data;
            }
        );
    },


    async getPdf(id, callback) {

        if (!id)
            return this.generateLocalEmptyDocument();

        return axiosV2("/pdf-documents/" + id).then(

            response => {

                if (callback) {
                    callback(response.data)
                }

                return response.data;
            }
        );
    },


    async openPdf_newWindow(id) {


        axiosV2("/pdf-documents/" + id).then(

            response => {

                let pdfWindow = window.open();

                pdfWindow.document.write(
                    "<style> body,html{margin:0;padding:0}</style> <iframe style='position:fixed;top:0;left:0;height:100vh;width:100vw' src='data:application/pdf;base64, " +
                    encodeURI(response.data.base64Content) + "'></iframe>"
                )

                pdfWindow.document.title = 'מסמך: ' + response.data.name;
            }
        );

    },

    async openPdf_modal(id,title) {


        let that = this;
        axiosV2("/pdf-documents/" + id).then(

            response => {
                window.planup.$root.$emit('showGlobalPdfIframeModal',that.base64ToBlobUrl(response.data.base64Content),title);
            }
        );

    },


    async updateName(documentData) {

        if (!documentData.id)
            return;

        this.updateDocumentInStore(documentData);

        return apiPost('/documents/updateName', documentData)
    },

    // async uploadPreview(documentId,canvas){

    //     return apiPost(`/documents/savePreviewImage/${documentId}`,{pngBase64:canvas.toDataURL()});
    // },


    // async getTemplates(withImages){
    //     if (withImages)
    //         return (await axiosV2('/documents/list?onlyTemplates=1&withImages=1')).data;

    //     return (await axiosV2('/documents/list?onlyTemplates=1')).data;
    // },


    async getList(withImages) {

        if (withImages)
            return (await axiosV2('/documents/list?withImages=1')).data;

        return (await axiosV2('/documents/list')).data;
    },


    async getGroup(groupName, withImages) {

        if (withImages)
            return (await axiosV2(`/documents/list/${groupName}?withImages=1`)).data;

        return (await axiosV2(`/documents/list/${groupName}`)).data;
    },


    getDocumentImageURL(id) {

        return `http://localhost:3000/apiv2/documents/getImage/${id}`;
    },


    base64ToBlobUrl(b64Data) {

        const sliceSize = 512;
        const contentType = 'application/pdf';
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return URL.createObjectURL(blob) + '#toolbar=0';
    },

}