import axiosV2, { apiRequest, apiPost } from '../core/http/axios_api_v2';



export default{

    generateLocalEmptyMilestone(){
        return {
            name: 'אבן דרך חדשה',
            showInCalendar:true,
            daysBeforeEvent: null,
            crewMembers: [],
            details: '',
            priority:1
        }
    },


    getMilestonesForEventClass(eventClassId,callback){
        axiosV2(`event-class-default-milestones/${eventClassId}`).then((response)=>{
            if (callback)
                callback(response.data.milestoneArray);
        })
    },


    updateEventMilestones(list,eventId){
        apiPost(`milestones/setEventMilestones/${eventId}`,list);
    }

}