<template>
  <div :class="showHeader ? wrapperClass : ''">
    <PageHeader v-if="showHeader" icon="i_meeting_tab" :title="$t('meeting')" :breadcrumbs="[{ name: $t('meeting'), route: '' }]">
    </PageHeader>
   
    <form action="" id="addEventForm_3" class="formWrap s-box p-3" @submit.prevent="addMeeting">

      <div class="row">



        <div class="ownerDetailsWrapper" v-for="contact in customer.contacts">

            <h4>
                <span v-for="title in CONTACT_TITLES">
                    <span v-if="title.id == contact.title">
                      {{ title.name }}
                    </span>
                </span>
            </h4>

            <CustomerContactFields :contact="contact"></CustomerContactFields>


            <hr>
          </div>
        

          <!-- add this eventually, i don't see it relevant to most businesses -->
        <div style="display:none" class="col-sm-6">
          <div class="">
            <div class="d-flex  items-center mb-3 gap-2">
              <div style="font-weight: bold" class="ml-3">
                <i class="fa fa-users"></i>
                משתתפים:
              </div>
              <AutoComplete url="/users/?search=" @selected="handleSetParticipants" :optionFormat="formatUserAutoComplete" v-if="!meeting.forAllEmployees"></AutoComplete>
              <FormInput display="horizontal" type="checkbox" label="לכל העובדים" v-model="meeting.forAllEmployees" :checked="meeting.forAllEmployees"></FormInput>
            </div>

            <span v-for="p of meeting.participants " :key="p.id">
              {{ p.name }} <i class="fa fa-times" @click="removeParticipant(p.id)"></i>
            </span>
          </div>
        </div>

        
      </div>



      <div class="row">

        <div class="col-sm-6">
            <FormSelect :options="meetingTypeList" label="סוג פגישה" v-model="meeting.type" required></FormSelect>
        </div>

        <div class="col-sm-6">
          <DatePicker v-model="meeting.date" :label="$t('תאריך הפגישה')"></DatePicker>
        </div>

        <div class="col-sm-6">
          <FormInput type="text" v-model="meeting.source" label="הגיע דרך"></FormInput>
        </div>

        <div class="col-sm-6">
          <FileManager ref="fileManager" :multiple="true"></FileManager>
        </div>

        <div class="col-sm-6">
          <label v-text="$t('תקפות עניין')"></label>
          <v-select multiple :options="monthList.map((m, i) => {
            return { code: i.toString(), label: m }
          })" v-model="meeting.monthOfIntrest"></v-select>

        </div>

      </div>

      <div class="row my-3">
        <div class="col-xs-12">
          <div class="inputWrap textareaWrap">
            <label for="eventNotes_3">{{ $t("notes") }}</label>
            <textarea title="eventNotes_3" id="eventNotes_3" class="noteWrapper" v-model="meeting.notes"></textarea>
            <span class="charsLength"><span class="charNum">200</span>/200</span>
          </div>
        </div>
      </div>

      <div class="inputWrap submitWrap">
        <button class="submit">
          {{ $t("saveAppointment") }}
        </button>
      </div>

    </form>

  </div>
</template>

<script>

import DatePicker from "../Common/DatePicker.vue";
import FileManager from "../Common/FileManager.vue";
import AutoComplete from "../../components/Events/AutoComplete";
import dateHelper from "@/helpers/date-helper.js";
import { monthList } from "@/helpers/constants.js";
import axiosV2, { apiRequest } from '../../core/http/axios_api_v2';
import Multiselect from "vue-multiselect";
import { dateToStr } from "../../helpers/date-helper";
import CONTACT_TITLES from "../Customers/CustomerContactTitles.json";
import CustomerContactFields from "../Customers/CustomerContactFields.vue";

export default {
  components: { DatePicker, AutoComplete, FileManager, Multiselect,CustomerContactFields },

  data() {
    return {
      types: {},
      monthList,
      meeting: this.newMeeting(),
      customer: {},
      CONTACT_TITLES: CONTACT_TITLES
    };
  },
  computed: {
    meetingTypeList() {
      return Object.keys(this.types).map(key => {
        return { content: this.types[key], value: key }
      })
    }
  },
  props: {
    forEvent: {
      type: Object,
      default: () => null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    wrapperClass: {
      type: String,
      default: 'innerMainContent'
    }
  },
  watch: {
    forEvent: function(newVal, oldVal) {
      console.log(newVal,oldVal)
      this.meeting.event = oldVal
    },
    
  },
  mounted() {
    this.types = this.$store.state.meetingTypes

    axiosV2('/customers/'+this.$router.currentRoute.params.customerId).then(
      (response) => this.customer=response.data
    )

    const meetingId = this.meeting.id || this.$route.params.meetingId;
    // console.log('loading meeting', meetingId)
    if (meetingId != null && meetingId > 0) {
      apiRequest('get', "meeting/" + meetingId)
        .then((meeting) => {
          this.meeting = meeting;
          this.$refs.fileManager.setFiles(meeting.files);
        })
    } else {

      // set meeting event
      const query_forEvent = this.$route.query.forEvent;
      if(query_forEvent) {
        this.meeting.event = this.$store.state.EventStore.event
        this.meeting.title = `פגישה עם  ${this.$store.state.EventStore.event.name}`;
        this.meeting.contactPhone = this.$store.state.EventStore.event.owner.phone;
      } else if(this.forEvent) {
        this.meeting.event = this.forEvent
        this.meeting.title = `פגישה עם  ${this.forEvent.name}`;
        this.meeting.contactPhone = this.forEvent.owner.phone;
      }

      // set meeting date
      const d = this.$router.currentRoute.query.date;
      if (typeof d !== "undefined" && d != "") {
        let d = dateHelper.arrToDate( this.$router.currentRoute.query.date.split("-"), "DD-MM-YYYY" );
        this.setDate(d);
      }
    }
  },
  methods: {
    async newMeeting() {


      


      return {
        id: null,
        date: new Date(),
        location: "",
        contactPhone: "",
        title: "",
        files: [],
        notes: "",
        type: null,
        monthOfIntrest: [],
        participants: [],
        customer: this.customer
      }
    },

    removeParticipant(id) {
      this.meeting.participants = this.meeting.participants.filter(x => x.id != id)
    },

    formatUserAutoComplete(user) {
      return `${user.name} -  ${user.email}`
    },

    formatEventAutoComplete(event) {
      return `${event.name} -  ${dateToStr(new Date(event.date), 'd-m-y')}`
    },

    handleSetEvent(event) {
      this.meeting.event = event;
      try {
        this.meeting.email = event.eventPlanner.ownerDetails.at(0).email;
        this.meeting.contactPhone = event.eventPlanner.ownerDetails.at(0).phone;
      } catch { }
      this.meeting.title = `פגישה עם  ${event.name}`;
      this.$forceUpdate()
    },

    handleSetParticipants(client) {
      this.meeting.participants = this.meeting.participants.filter(x => x.id != client.id)
      this.meeting.participants.push({ id: client.id, name: client.name, phone: client.phone })
      this.$forceUpdate()
    },

    setDate(date) {
      this.meeting.date = date;
    },

    addMeeting() {
      if (this.$refs.fileManager.filesToUpload.length > 0)
        this.$refs.fileManager.upload().then((response) => {
          if (response.status != 200) {
            this.AlertError();
            return;
          }
          this.meeting.files = response.files.map((a) => a.id);
          this.saveMeeting();
        });
      else this.saveMeeting();
    },

    saveMeeting() {
      const meeting = { ...{}, ...this.meeting }

      if (Array.isArray(meeting.monthOfIntrest))
        meeting.monthOfIntrest = meeting.monthOfIntrest.map(m => m.code).join(',')

      meeting.id = undefined
      this.$store
        .dispatch("addMeeting", meeting)
        .then((response) => {
          this.AlertSuccess();
          this.meeting = this.newMeeting()
        })

    },

  },
};
</script>

<style>

</style>