import axiosV2, { apiPost } from '../core/http/axios_api_v2';


export default {

     
    messagesAwaitingAck: {},

    
    activeChats:{},



    getStatus() {

        return axiosV2('whatsapp/getStatus').then(res => {

            planup.$store.state.whatsappStatus = res?.data?.phone ? res.data.phone + ' מחובר' : 'לא מחובר';

        })

    },


    getNumberInfo(){
        let number = window.prompt('תרשום מספר טלפון כמו 0544636433');
        return axiosV2(`whatsapp/getNumberInfo/${number}`).then(res=>{
            document.write(`<pre>${res.data}</pre>`);
        })
    },

    sendMessage(to, msg) {

        if (!msg) {
            msg = prompt('מה לשלוח?');
        }

        let formattedNumber = to.slice(0, 2) == '05' ? to.slice(1) : to;


        let messageId = `${Math.floor(Math.random() * 1000000)}-${Date.now()}`;

        apiPost(`whatsapp/sendMessage`, { to: formattedNumber, msg, messageId }, true)




        let messageToReturn = {
            message: msg,
            messageId: messageId,
            incomingFromCustomer: false,
            created: new Date(),
            typeOfMessage:'whatsapp',
            gotWhatsappAck: false,
            createdLocally: true
        }


        this.messagesAwaitingAck[messageId] = messageToReturn;

        return messageToReturn;


    },

    getQr() {
        return axiosV2('whatsapp/getQr').then(res => {
            return res.data
        })
    },

    async getProfilePic(contact) {

        let picUrl = await apiPost('whatsapp/getProfilePic', { contactId: contact.id });
        console.log(picUrl);
        contact.pic = picUrl;
        return picUrl;


    },

    disconnect() {
        
        return apiPost('whatsapp/disconnect').then(res =>{
            planup.$store.state.businessSettings.whatsappServer = '';
            planup.$store.state.whatsappStatus = 'לא מחובר';
        });
    },


    handleAck(messageData){


        this.messagesAwaitingAck[messageData.messageId].gotWhatsappAck = true;

    },


    handleIncomingMessage(messageFromSocket) {


        let message = {
            msgGroup: `whatsapp_contact_${messageFromSocket.contactId}`,
            contactId: messageFromSocket.contactId,
            customerId: messageFromSocket.customerId,
            typeOfMessage:'whatsapp',
            message: messageFromSocket.body,
            incomingFromCustomer: true,
            created: new Date(),

        }

        if (this.activeChats[`whatsapp_contact_${message.contactId}`]) {
                
                this.activeChats[`whatsapp_contact_${message.contactId}`].unshift(message);
                return;
        }



        window.planup.$handlers.customerHandler.getContactData(message.contactId).then(contact => {


            let picUrl = window.planup.$handlers.customerHandler.getContactImage(contact);

            let contactName = window.planup.$handlers.customerHandler.getDisplayName(contact);
            let notificationText = 'הודעה חדשה מ' + contactName;

            window.planup.$notify({
                type: `whatsapp customer${message.customerId} contact${message.contactId}`,
                title: 'hidden',
                text: `<img src="${picUrl}"><div class="whatsappMsgBody">${notificationText}</div><div style="clear:both"></div>`,
            })

        })

    },

    handleConnected() {

        planup.$root.$emit('whatsappConnected');

    }





}