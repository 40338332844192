<template>
    <div>
      <div class="innerMainContent">
        <PageHeader icon="i_user" :title="$t('editUserServices')" :breadcrumbs="[{ name: $t('editUserServices'), route: null }]">
        </PageHeader>
  
  
        <div class="d-flex">
    
          <div class="s-box flex-fill p-5">


                    היוזר הוא:

                    {{ userToChangeTheirSettings.name }}


                  <br>
                  <br>
                  <br>

                  <ul>
                    <li v-for="service in bizServices">

                      <input type="checkbox" :value="service.id" :id="'service' + service.id" v-model="userToChangeTheirSettings.bizServices">
                      <label :for="'service' + service.id" style="cursor:pointer">
                        {{ service.name }}
                      </label> 



                    </li>
                  </ul>

                  <br>
                  <br>


                  <button class="s_btn" @click="save">
שמור שינויים
                  </button>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

    import axiosV2, { apiRequest } from "../../core/http/axios_api_v2";

    export default {
    name: "UserServices",
    data() {
        return {
          userToChangeTheirSettings: {},
            app: {},
            bizServices: null
        }
    },

    methods: {
      save(){

        apiRequest('Post','biz-services/updateUserServices/'+this.userToChangeTheirSettings.tenant,{services:this.userToChangeTheirSettings.bizServices})

      }
    },

    mounted() {},

    beforeMount() {
        

        const userToChangeTheirSettingsId = this.$router.currentRoute.params.id;

        axiosV2
                .get('root-admin/' + userToChangeTheirSettingsId)
                .then((res)=>{

                    this.userToChangeTheirSettings = res.data;

                  })

                  this.$store
      .dispatch("restGet", { 'route': 'biz-services' })
      .then((response) => {
        this.bizServices = response;
      })



    }
    }



  
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>
  