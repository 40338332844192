
import hasPermission from "./acl"
import store from '../../store';
function commentNode(el, vnode) {
    const comment = document.createComment(' ')

    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
    })

    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.tag = undefined

    vnode.data = vnode.data || {}
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
        // @ts-ignore
        vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
        el.parentNode.replaceChild(comment, el)
    }
}

export default {
    install: function (Vue, options) {

        Vue.directive('can', function (el, bindings, vnode) {
            var user = store.getters['getUser'];
            
            //let permmision = bindings.rawName.split(':').at(-1);
            let permmision = `${bindings.arg}.${bindings.expression.replace(/['\"]/ig, '')}`

            const behaviour = 'hide'; // binding.modifiers.disable ? 'disable' : 'hide'
          
            let ok = hasPermission(user, permmision)
            if (!ok) {
                // console.warn('no permission to view this part', permmision)
                if (behaviour === 'hide') {
                    commentNode(el, vnode)
                } else if (behaviour === 'disable') {
                    el.disabled = true
                }
            }
        })
    }
}