// i think this is actually a very naive implementation of debounce
// because we can only debounce one function at a time
// there is only one timer

var timer;
export function debounce(func, timeout = 300){
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }