import axiosV2, { apiRequest, apiPost } from '../core/http/axios_api_v2';

export default{

    saveFilter(name,filter,reportSection){
        apiPost('saved-report-filters',{name,filter,reportSection})
    },

    getFilters(){
        return axiosV2('saved-report-filters').then(res => res.data);
    }
}