import Role from '../../core/auth/roles.enum';

export default [
    {
        path: '/events',
        name: 'events',
        component: () => import('@/components/Events/EventList'),
        meta: {
            requiresAuth: true,
            matchHeight: true
        }
    },

    {
        path: '/events/add/:customerId',
        name: 'addEvent',
        component: () => import('@/components/Events/AddEvent'),
        meta: {
            requiresAuth: true,
            havePermission: 'event.create',
            matchHeight: true
        }
    },

    {
        path: '/events/add/:customerId/:date',
        name: 'addEventDate',
        component: () => import('@/components/Events/AddEvent'),
        meta: {
            requiresAuth: true,
            havePermission: 'event.create',
            matchHeight: true
        }
    },

    {
        path: '/events/:id',
        name: 'editEvent',
        component: () => import('@/components/Events/EventEdit'),
        meta: {
            requiresAuth: true,
            havePermission: 'event.update',
            matchHeight: true
        }
    },

    {
        path: '/event/:type/:id',
        name: 'EventMiddleScreen',
        component: () => import('@/components/MiddleScreen/EventMiddleScreen'),
        meta: {
            requiresAuth: true,
            userInRole: [Role.Admin, Role.Resseler, Role.HallManager, Role.HallManagerChild],
            matchHeight: true
        }
    },
    {
        path: '/:eventId/live-arrival-count',
        name: 'LiveArrivalCount',
        component: () => import('@/components/Guests/LiveArrivalCount'),
        meta: { requiresAuth: true, }
    },

]