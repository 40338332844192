<template>

    <Modal ref="modal"
           :hideFooter="true"
           title="ביטול אירוע">
        <template v-slot:body
                  v-if="event">
            
                האם לבטל את האירוע
                <strong>{{ event.name }}</strong>?

                <br>
                <br>
            

            השלבים הדרושים לביצוע הביטול:
            <br>


            <ol>

            

            <li v-if="event.planupSystemStatus == 'hazmanaSigned' || event.planupSystemStatus == 'hazmanaSent' ">

                יצירת הזמנת ביטול
                
            </li>

            

            <li>
                סימון האירוע בסטטוס מבוטל
            </li>

        </ol>

        </template>

    </Modal>

</template>
<script>
export default {
    name: 'CancelEvent',
    data() {
        return {
            event: null,
        }
    },
    methods: {
        show(event) {
            this.$refs.modal.show();
            this.event = event;
        },
        hide() {
            this.$refs.modal.hide();
        },
    }
}
</script>