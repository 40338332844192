<template>
   
        <div>
                <ul>
                    <li v-for="(lang) in $t('langs')">
                        <a href="#" @click.prevent="setLang(lang.code)">
                            <span>{{lang.name}}</span>
                        </a>
                    </li>
                </ul>
        </div>
    
</template>

<script>
    import Vue from 'vue'
    export default {
        name: 'LanguageSelector',
        data() {
            return{}
        },
        computed:{
            getCurrentLang(){

            }
        },
        methods:{
            setLang(lang){
                Vue.i18n.set(lang);
                this.$store.dispatch('saveLang',lang);
            }
        }
    }

</script>

<style scoped>
.selectBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>