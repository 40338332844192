<template>
    <div class="chatWrapper">
        <div class="chat-header">


            <div :class="typeOfChat"></div>

            <img style="border:solid 1px #eeeeee"
                 onerror="this.src='https://www.mit4mit.co.il/img/biz_placeHolder.png'"
                 :src="$handlers.customerHandler.getContactImage(contact)">


            <div style="float:right;padding-right:10px;"
                 v-if="contact">

                <div style="color: #2a3142;letter-spacing: 1px;font-weight: 600;"
                     class="chatname">
                    {{ $handlers.customerHandler.getDisplayName(contact) }}
                    &nbsp;
                </div>

                <div style="color: #aaaaaa;letter-spacing: 1px;font-size: 12px;margin-top: 5px;"
                     v-if="messages.length">

                    הודעה אחרונה ב

                    {{ $globals.niceDate(messages[messages.length - 1].created) }}

                    בשעה

                    {{ $globals.niceTime(messages[messages.length - 1].created) }}

                </div>

            </div>

            <div style="clear:both"></div>

        </div>





        <div ref="messageContainer"
             class="custom-scrollbar"
             style="padding:15px;padding-top:20px;height:calc(100vh - 455px);overflow:auto;">







            <div v-for="message in messages.slice().reverse()">




                <div v-if="message.incomingFromCustomer"
                     class="chatIncomingMessage">

                    <img :src="$store.state.logoImageUrl"
                         onerror="this.src='https://www.mit4mit.co.il/img/biz_placeHolder.png'">



                    <div style="padding-top:20px;">
                        {{ message.message }}
                    </div>

                    <div class="chatIncomingMessageInnerInfoTime">


                        <div>

                            <span v-if="$globals.niceDate(message.created) != $globals.niceDate(new Date())">

                                {{ $globals.niceDate(message.created) }}

                                בשעה

                            </span>



                            {{ $globals.niceTime(message.created) }}

                        </div>

                        




                    </div>

                </div>

                <div v-if="!message.incomingFromCustomer"
                     class="chatOutgoingMessage">

                    <img :src="$store.state.logoImageUrl"
                         onerror="this.src='https://www.mit4mit.co.il/img/biz_placeHolder.png'">



                    <div style="padding-top:20px;">
                        {{ message.message }}
                    </div>

                    <div class="chatOutgoingMessageInnerInfoTime">


                        <div v-if="message.gotWhatsappAck">

                            <span v-if="$globals.niceDate(message.created) != $globals.niceDate(new Date())">

                                {{ $globals.niceDate(message.created) }}

                                בשעה

                            </span>



                            {{ $globals.niceTime(message.created) }}

                        </div>

                        <div v-else-if="message.createdLocally">


                            שולח
                            <i class="fa-solid fa-spinner fa-spin"></i>



                        </div>

                        <div v-else>
                            <span style="color:darkred;">

                                הודעה לא נשלחה
                            </span>
                        </div>





                    </div>

                </div>

                <div style="clear:both"></div>

            </div>

        </div>






        <div class="chat-input-wrapper">


            <div v-if="!$store.state.whatsappStatus.includes('0')" style="text-align: center;">
                
                חשבון הואצאפ אינו מחובר - לחיבור לחצו 
                <span style="cursor:pointer;color:#ab8ce4;" @click="$('.modal').modal('hide') && $router.push('/settings?activeTab=orginizationSettings_googleTab')">כאן</span>.
            </div>



            <div class="chat-input-inner-wrapper"
            v-if="$store.state.whatsappStatus.includes('0')"
                 style="border:2px solid #ab8ce4;border-radius:5px;background:white;overflow:hidden;position:relative;">


                <input v-model="messageToSend"
                       ref="messageToSendInput"
                       @keyup.enter="sendMessage"
                       style="width:calc(100% - 100px);border:none !important;outline:none !important;padding:10px; padding-right: 15px;"
                       placeholder="כתבו הודעה או                       ...">




                <div @click="$refs.messageTemplatePopup.show()"
                     @text="messageToSend = $event"
                     style="position:absolute;top:10px;right:93px;color:#ab8ce4;cursor:pointer"
                     v-show="!messageToSend">
                    בחרו תבנית
                </div>


                <MessageTemplatePopup @text="messageToSend = $event"
                                      ref="messageTemplatePopup"></MessageTemplatePopup>



                <button class="btn btn-primary"
                        @click="sendMessage"
                        style="    border-radius: 0px;width: 100px;padding: 8px;font-size: 17px;">
                    שלח
                </button>



            </div>




        </div>

    </div>
</template>
<script>

import MessageTemplatePopup from '@/components/MessageTemplates/MessageTemplatePopup.vue';

export default {
    name: 'PlanupMultiMessagesChat',
    props: ['msgGroup'],
    data() {
        return {
            messageToSend: '',
            typeOfChat: '',
            messages: [],
            contact: {}
        }
    },

    methods: {

        scrollToBottom() {

            this.$nextTick(() => {
                this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
            })

        },

        sendMessage() {

            if (!this.messageToSend)
                return;

            this.messages.unshift(this.$handlers.whatsappHandler.sendMessage(this.contact.phone, this.messageToSend));

            this.messageToSend = null;

            this.scrollToBottom();

        }
    },

    watch: {

        messages: function () {
            this.scrollToBottom();
        },

        msgGroup: {
            immediate: true,
            handler: function (msgGroup, oldValue) {

                delete this.$handlers.whatsappHandler.activeChats[oldValue];
                
                if (!msgGroup)
                    return;

                this.$handlers.planupMultiMessagesHandler.loadConversation(msgGroup).then(data => {

                    this.messages = data.messages;

                    this.contact = data.contact;

                    // this.typeOfChat = data.messages[0].typeOfMessage;
                    this.typeOfChat = msgGroup.slice(0, 8) == 'whatsapp' ? 'whatsapp' : 'email';

                    this.$handlers.whatsappHandler.activeChats[msgGroup] = this.messages;


                    this.scrollToBottom();

                })

                


            }
        },
    },
    components: { MessageTemplatePopup }


}
</script>