<!-- 



        this component receives tabs as a prop - 
            1) single date
            2) multiple dates / options
            3) date range




-->


<template>
    <div>


        <input class="form-control"
               @click="openModal()"
               v-model="textToShowInForm">



        <!-- <button class="btn btn-primary ms-1"
                @click="openModal()">
            פתח יומן
            &nbsp;
            <i class="fa-regular fa-calendar"></i>
        </button> -->


        <Modal title="בחירת תאריך לאירוע"
               ref="EventDatePickerModal"
               :hideHeader="true"
               :xlarge="true">

            <template v-slot:body>


                <div v-if="tabs && tabs.length > 1"
                     class="yonsoTabsWrapper">


                    <div v-if="tabs.includes(1)"
                         class="yonsoTab"
                         :class="{ active: activeTab == 1 }"
                         @click="setActiveTab(1)">
                        <label>
                            תאריך ידוע
                        </label>
                    </div>

                    <div v-if="tabs.includes(2)"
                         class="yonsoTab"
                         :class="{ active: activeTab == 2 }"
                         @click="setActiveTab(2)">
                        <label>
                            מספר אפשרויות
                        </label>
                    </div>

                    <div v-if="tabs.includes(3)"
                         class="yonsoTab"
                         :class="{ active: activeTab == 3 }"
                         @click="setActiveTab(3)">
                        <label>
                            טווח תאריכים
                        </label>
                    </div>

                </div>


                <div v-if="tabs.includes(1)"
                     v-show="activeTab == 1"
                     style="text-align: center;">


                    <h5 style="text-align: right;">


                        בחרו את התאריך שהלקוח מבקש
                    </h5>



                    <div class="planupCalendarWrapper">

                        <PlanupCalendar :showTodayAgendaOnPopup="false"
                                        :confirmDateOnClick="true"
                                        :disableClickDatePopup="true"
                                        :hideDisplayTypeSelector="true"
                                        ref="singleDateCalendar"
                                        :disableItemClicks="true"
                                        @chooseDate="chooseDate($event)"
                                        @chooseTime="chooseTime($event)">
                        </PlanupCalendar>

                    </div>

                    <!-- <calendar-view :timeFormatOptions="{ hour: 'numeric', minute: 'numeric' }"
                                   display-period-uom="month"
                                   :displayPeriodCount="1"
                                   :items="calendarItems"
                                   @click-date="clickDate_singleDate"
                                   :style="'height:700px!important'"
                                   class="theme-default">

                        <template #dayContent="h">
                            <div v-html="$globals.hebrewDateDay(h.day)"
                                 class="cv-date-heb"></div>
                        </template>

</calendar-view> -->

                </div>

                <div v-if="tabs.includes(2)"
                     v-show="activeTab == 2"
                     style="text-align: center;">

                    <h5 style="text-align: right;">
                        סמנו את התאריכים שהלקוח מעוניין בהם
                    </h5>


                    <calendar-view :timeFormatOptions="{ hour: 'numeric', minute: 'numeric' }"
                                   display-period-uom="month"
                                   :displayPeriodCount="1"
                                   :items="calendarItems"
                                   @click-date="clickDate_multipleOptions"
                                   :style="'height:700px!important'"
                                   class="theme-default">

                        <template #dayContent="h">
                            <div v-html="$globals.hebrewDateDay(h.day)"
                                 class="cv-date-heb"></div>

                            <div v-if="specificRequestedTimestamps.includes(h.day.valueOf())"
                                 class="markedCalendarDate">

                                <div style="font-size:30px;margin-bottom:5px;margin-top:15px;">
                                    <i class="fa fa-check"></i>
                                </div>

                                {{ $globals.niceDate(h.day) }}
                                <br>
                                {{ $globals.hebrewDateDay(h.day) }}

                            </div>


                        </template>

                    </calendar-view>

                    <div style="text-align: right;">
                        תאריכים שנבחרו:

                        <span v-for="t in specificRequestedTimestamps">
                            {{ $globals.niceDate(new Date(t)) }},
                        </span>


                    </div>

                </div>

                <div v-if="tabs.includes(3)"
                     v-show="activeTab == 3"
                     style="text-align: center;"
                     class="hideFlatpickr">


                    <h5 style="text-align: right;">
                        בחרו את טווח התאריכים שהלקוח מעוניין בו
                    </h5>


                    <DatePicker v-model="rangeStart"
                                ref="DatePickerStart"
                                :enableTime="false"
                                :onDayCreate="fillDatePickrRange"
                                :inline="true"></DatePicker>

                    <DatePicker v-model="rangeEnd"
                                ref="DatePickerEnd"
                                :onDayCreate="fillDatePickrRange"
                                :enableTime="false"
                                :inline="true"></DatePicker>

                    <div style="text-align: right;">
                        הטווח שנבחר:

                        <span v-if="rangeStart">
                            {{ rangeStart }}
                        </span>

                        -

                        <span v-if="rangeEnd">
                            {{ rangeEnd }}

                        </span>



                    </div>

                    <div>



                        <div style="text-align: right;user-select: none;">

                            ימים בשבוע:

                            &nbsp;

                            <label>
                                <input type="checkbox"
                                       v-model="rangeDaysOfWeek.sun">
                                א'
                            </label>

                            &nbsp;


                            <label>
                                <input type="checkbox"
                                       v-model="rangeDaysOfWeek.mon">
                                ב'
                            </label>

                            &nbsp;

                            <label>
                                <input type="checkbox"
                                       v-model="rangeDaysOfWeek.tue">
                                ג'
                            </label>

                            &nbsp;

                            <label>
                                <input type="checkbox"
                                       v-model="rangeDaysOfWeek.wed">
                                ד'
                            </label>

                            &nbsp;


                            <label>
                                <input type="checkbox"
                                       v-model="rangeDaysOfWeek.thu">
                                ה'
                            </label>

                            &nbsp;

                            <label>
                                <input type="checkbox"
                                       v-model="rangeDaysOfWeek.fri">
                                ו'
                            </label>

                            &nbsp;

                            <label>
                                <input type="checkbox"
                                       v-model="rangeDaysOfWeek.sat">
                                ש'
                            </label>




                        </div>



                    </div>

                </div>








            </template>

            <template v-slot:buttons>
                <button @click="emitDates()"
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal">
                    {{ $t('continue') }}
                </button>
            </template>



        </Modal>
    </div>
</template>
<script>


import PlanupCalendar from '../Calendar/PlanupCalendar.vue';
import DatePicker from '../Common/DatePicker';
import Calendar from '../Calendar/Calendar';
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";

export default {
    name: 'EventDatePicker',
    data() {
        return {
            calendarItems: [],
            activeTab: this.tabs[0],
            specificRequestedTimestamps: [],
            rangeDaysOfWeek: {},
            rangeStart: null,
            rangeEnd: null,
            textToShowInForm: this.placeHolder,
        }
    },
    watch: {
        //not sure this is the correct way to do this
        placeHolder(newVal) {
            this.textToShowInForm = newVal;
        },

        rangeStart(newValue) {

            let dateParts = newValue.split('/');
            this.rangeStartDateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

            this.$refs.DatePickerStart.redraw();
            this.$refs.DatePickerEnd.redraw();

        },

        rangeEnd(newValue) {

            let dateParts = newValue.split('/');
            this.rangeEndDateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

            this.$refs.DatePickerStart.redraw();
            this.$refs.DatePickerEnd.redraw();

        },
    },
    props: {
        placeHolder: { type: String },
        tabs: {
            type: Array,
            required: true
        },
        justDateNoTime: {
            default: false,
        }
    },
    methods: {


        fillDatePickrRange(dObj, dStr, fp, dayElem) {


            if (!this.rangeStartDateObj || !this.rangeEndDateObj)
                return;

            if (dayElem.dateObj > this.rangeStartDateObj && dayElem.dateObj < this.rangeEndDateObj) {
                dayElem.classList.add('insidePlanupRange')
            }

        },

        chooseTime($event) {

            this.$emit('setTime', $event ? `${$event.toString().length > 1 ? $event : '0' + $event}:00` : null);
            this.$refs.EventDatePickerModal.hide();
        },


        chooseDate($event) {

            if (!this.justDateNoTime) {
                this.$refs.singleDateCalendar.singleDayViewDate = $event;
                this.$refs.singleDateCalendar.setDayView();
            }

            // this.$refs.EventDatePickerModal.hide();
            this.textToShowInForm = this.$globals.niceDate($event);
            this.$emit('singleDate', $event)

            if (this.justDateNoTime) {
                this.closeModal();
            }

        },

        clickDate_multipleOptions(day) {

            day.setHours(0, 0, 0, 0);

            let dayTimestamp = day.valueOf();
            let index = this.specificRequestedTimestamps.indexOf(dayTimestamp);

            if (index == -1) {

                this.specificRequestedTimestamps.push(dayTimestamp);

            } else {

                this.specificRequestedTimestamps.splice(index, 1);
            }
        },


        emitDates() {

            if (this.activeTab == 2) {

                this.$emit('multipleOptions', this.specificRequestedTimestamps.map(t => new Date(t)));

            } else if (this.activeTab == 3) {

                let daysOfWeekArray = [];

                for (let key in this.rangeDaysOfWeek) {
                    if (this.rangeDaysOfWeek[key]) {
                        switch (key) {
                            case 'sun':
                                daysOfWeekArray.push('א')
                                break;
                            case 'mon':
                                daysOfWeekArray.push('ב')
                                break;
                            case 'tue':
                                daysOfWeekArray.push('ג')
                                break;
                            case 'wed':
                                daysOfWeekArray.push('ד')
                                break;
                            case 'thu':
                                daysOfWeekArray.push('ה');
                                break;
                            case 'fri':
                                daysOfWeekArray.push('ו')
                                break;
                            case 'sat':
                                daysOfWeekArray.push('ש')
                                break;
                        }

                    }

                }

                this.$emit('dateRange', this.rangeStart, this.rangeEnd, daysOfWeekArray);

            }

        },

        openModal() {
            this.$refs.singleDateCalendar.setMonthView();
            this.$refs.EventDatePickerModal.show();
        },
        closeModal() {
            this.$refs.EventDatePickerModal.hide();
        },
        setActiveTab(i) {
            this.activeTab = i;
        },
        save() {

        }

    },
    components: { DatePicker, Calendar, CalendarView, CalendarViewHeader, PlanupCalendar }
}
</script>
<style></style>