<template>
    <Modal ref="modal"
           :title="modalTitle"
           :hideFooter="true"
           :large="true || largeModal">
        <template v-slot:body>
            <PlanupMeetingForm ref="planupMeetingForm"
                               :summaryView="summaryView"
                               :verticalForm="true"
                               @saveMeeting="saveMeeting"
                               :meeting="meeting"
                               @switchToSmallModal="switchToSmallModal()"
                               :customer="customer"
                               :hideTitle="true"></PlanupMeetingForm>
        </template>
    </Modal>
</template>
<script>

import MeetingHandler from "../../modules/MeetingHandler";
import PlanupMeetingForm from "../Meeting/PlanupMeetingForm.vue";
import Modal from "../Common/Modal.vue";

export default {
    name: 'QuickMeetingModal',
    data() {

        return {
            largeModal: this.meetingToEdit ? true : false,
            modalTitle: 'פגישה',
            customer: null,
            meeting: this.meetingToEdit ? this.meetingToEdit : MeetingHandler.generateLocalEmptyMeeting(),
        }
    },
    props: ['meetingToEdit', 'summaryView'],
    watch: {
        meetingToEdit(newVal) {

            this.largeModal = this.meetingToEdit ? true : false,
                this.modalTitle = 'פגישה',
                this.meeting = newVal ? newVal : MeetingHandler.generateLocalEmptyMeeting()
        }
    },
    methods: {

        switchToSmallModal() {
            this.largeModal = false;
        },

        buildTitleForMeeting(meeting) {
            // return `${meeting.title}, יום ${this.$globals.dayOfWeekFromDate(meeting.date)} ${this.$globals.niceDate(meeting.date)} בשעה ${meeting.time}`

            return `פגישה: ${meeting.title}`;
        },


        show(customer, date) {

            this.customer = customer;
            if (!this.meetingToEdit)
                this.meeting = MeetingHandler.generateLocalEmptyMeeting(this.customer, date)

            this.$refs.planupMeetingForm.resetSummaryViewToPropValue()
            this.$refs.modal.show();
        },
        saveMeeting() {

            // let that = this;
            MeetingHandler.saveMeeting(this.meeting).then(meetingId => {

                this.meeting.id = meetingId;


                this.$emit('savedMeeting', this.meeting);

            })
            this.$refs.modal.hide();

        }
    },
    components: { Modal, PlanupMeetingForm }
}
</script>