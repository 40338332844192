<template>

  <div class="container-fluid"
       id="loginPage"
       style="opacity:1;transiton:all 1s linear"
       :class="{ fadeOutLogin: is_requesting }">

    <!-- <div class="pull-right">
    <button class="btn btn-outline-primary mt-3" @click="toggleLanguage">
      {{  $t('langName') }}
    </button>
  </div> -->

    <div class="authentication-main">
      <div class="row">
        <div class="p-0 col-md-4">
          <div class="auth-innerleft"
               style="background:url('/static/images/login_bg.png')">
            <div class="text-center">
              <img src="/static/planup.png">
              <hr>
              PlanUp
              -
              ניהול אירועים חכם
            </div>
          </div>
        </div>
        <div class="p-0 col-md-8">
          <div class="auth-innerright">
            <div class="authentication-box">
              <h4>
                {{ $t('login') }}
              </h4>
              <h6>
                {{ $t('enterUserAndPassword') }}
              </h6>
              <div class="mt-4 p-4 mb-0 card">
                <form class="theme-form"
                      @submit.prevent="submitLogin">
                  <div class="form-group">
                    <label class="col-form-label pt-0">
                      {{ $t("email") }}
                    </label>
                    <input type="email"
                           @keypress.enter.stop="submitLogin"
                           class="form-control form-control-lg"
                           v-model="email">
                  </div>
                  <div class="form-group">
                    <label class="col-form-label pt-0">
                      {{ $t("password") }}
                    </label>
                    <input type="password"
                           @keypress.enter.stop="submitLogin"
                           class="form-control form-control-lg"
                           v-model="password">
                  </div>
                  <div class="form-row mt-3 form-group">
                    <div class="col-md-3">
                      <button @click="submitLogin"
                              type="button"
                              class="btn btn-primary btn-block">
                        {{ $t('loginButton') }}
                      </button>
                    </div>
                    <div class="col-md-3">

                      <router-link to="/login/recover"
                                   class="loginBtn">
                        <button class="btn btn-outline-primary">
                          {{ $t("forgotPw") }}
                        </button>
                      </router-link>




                    </div>




                  </div>

                  <hr>

                  <div class="social mt-3">
                    <div class="btn-showcase form-row">
                      <div class="col-sm-6 col-md-3">
                        <button @click="loginWithFacebook"
                                type="button"
                                class="btn btn-social-btn btn-fb">
                          <i class="fa-brands fa-facebook"></i>
                          &nbsp;

                          פייסבוק
                        </button>
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <button type="button"
                                @click="loginWithGoogle"
                                class="btn btn-social-btn btn-google">


                          <i class="fa-brands fa-google"></i>
                          &nbsp;

                          גוגל

                        </button>

                        <div style="display:none !important">
                          <component is="script"
                                     src="https://accounts.google.com/gsi/client"
                                     async></component>
                          <div id="g_id_onload"
                               data-client_id="340965126822-psnjm1h1h26c5lgcreik4vjg39seepoo.apps.googleusercontent.com"
                               data-login_uri="https://planup.co.il/apiv2/tenancy/google"
                               data-auto_prompt="false">
                          </div>
                          <div class="g_id_signin"
                               data-type="standard"
                               data-size="large"
                               data-theme="outline"
                               data-text="sign_in_with"
                               data-shape="rectangular"
                               data-logo_alignment="left">
                          </div>
                        </div>




                      </div>
                      <div class="col-sm-6 col-md-3">
                        <button type="button"
                                class="btn btn-social-btn btn-github btn-block"
                                style="background-color: rgb(141, 110, 99); color: rgb(255, 255, 255);">
                          <i class="fa-regular fa-envelope"></i>
                          &nbsp;
                          SMS
                        </button>
                      </div>


                    </div>
                  </div>

                </form>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Vue from 'vue';
import axiosV2 from '../core/http/axios_api_v2';

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      is_requesting: !!this.$router.currentRoute.query.loginToken,
      opacity: 0,

    };
  },
  components: {

  },
  computed: {
  },
  methods: {


    loginWithFacebook() {

      window.FB.login(function (response) {

        if (!response.status == 'connected' || !response.authResponse.accessToken) {
          alert('שגיאה');
          return;
        }

        axios.post('facebook/login', { token: response.authResponse.accessToken })

      }, { scope: 'email,pages_read_engagement' });

    },

    loginWithGoogle() {
      const googleLoginWrapper = document.createElement("div");
      googleLoginWrapper.style.display = "none";
      document.body.appendChild(googleLoginWrapper);

      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200",
      });

      googleLoginWrapper.querySelector("div[role=button]").click();
    },
    // toggleLanguage() {

    //   if (Vue.i18n.locale() == 'he') {

    //     document.body.classList.remove('rtl');
    //     Vue.i18n.set('en');
    //     this.$store.dispatch('saveLang', 'en');

    //   } else {

    //     document.body.classList.add('rtl');
    //     Vue.i18n.set('he');
    //     this.$store.dispatch('saveLang', 'he');

    //   }

    // },
    submitLogin(e) {
      // debugger;

      this.$data.is_requesting = true;
      this.$store
        .dispatch("makeLogin", { email: this.email, password: this.password })
        .then((user) => {


          // debugger;

          // console.log('before connect socket')

          if (user) {
            //   this.socketConnect(user.id, user.tenant)
            this.$router.push("/");
          }



          console.log('All ok in submitLogin and makeLogin');

        })
        .catch((response) => {

          // debugger;

          this.$data.is_requesting = false;
          this.AlertError(this.$t("invalidLogin"))
          console.error(response)
        });
    },
    async socketConnect(userId, tenantId) {

      // console.log('Connecting socket')

      // const socketUser = tenantId ? `${tenantId}_${userId}` : `${userId}`

      // const socketUser = tenantId;
      // await this.$socket.connect()
      // this.$socket.emit('setUser', socketUser)
      // this.$socket.emit('hello',{test:'test'})




      // console.log('setuser');


    }
  },



  mounted() {

    if (typeof this.$router.currentRoute.query.userNotFound != 'undefined') {

      this.$notify({
        type: 'error',
        title: 'שגיאה',
        text: 'המשתמש לא נמצא',
        duration: 2500
      })
    }


    if (this.$router.currentRoute.query.loginToken) {

      axios.post(`tenancy/loginWithLoginToken`, { singleUseToken: this.$router.currentRoute.query.loginToken }).then(response => {

        console.log(response.data);

        this.$store.commit('setAccessToken', response.data.token)
        this.$store.commit('setTenant', response.data.tenant)
        this.$router.push("/");
        this.socketConnect(1, response.data.tenant); //need to test this
        console.log('test socket connection')
      })


    }




  }
};
</script>

<style scoped>
.fadeOutLogin {
  opacity: 0 !important
}
</style>