<template>
    <Modal ref="modal"
           title="בחרו תבנית להודעה"
           :hideFooter="true">

        <template v-slot:body>

            <div>

                בחרו את התבנית להודעה שאתם רוצים.

                לאחר מכן ניתן יהיה לערוך שינויים.
                <br>


                <Multiselect :options="$store.state.messageTemplates"
                             deselect-label=""
                             placeholder=""
                             v-model="chosenMessageTemplate"
                             selectLabel=""
                             track-by="id"
                             label="title"
                             class="mb-3"
                             selectedLabel=""
                             :taggable="false">


                </Multiselect>

                <br>
                תצוגה מקדימה:
                <br>


                <textarea disabled
                          class="form-control"
                          style="height: 200px;"
                          v-model="chosenMessageTemplate.text">


                        </textarea>

                <br>
                <br>


                <button class="btn btn-primary pull-right"
                        @click="$emit('text', chosenMessageTemplate.text) && $refs.modal.hide()">

                    בחר

                </button>


            </div>

        </template>

    </Modal>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'MessageTemplatePopup',
    methods: {
        show() {
            this.$refs.modal.show();
        },
    },
    data() {
        return {
            chosenMessageTemplate: {}
        }
    },
    components: { Multiselect }
}

</script>