import ROLES_NUM from '../../core/auth/roles.enum';

export default [
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('@/components/Calendar/CalendarPage2'),
        meta: {
            requiresAuth: true,
            havePermission: 'calendar.view',
            matchHeight: true
        }
    },
    {
        path: '/calendar/add/:itemType',
        name: 'calendar-item-create',
        component: () => import('@/components/Calendar/CalendarItemForm'),
        meta: {
            requiresAuth: true,
            havePermission: 'calendar.view',
            matchHeight: true
        }
    },
    {
        path: '/calendar/:itemId',
        name: 'calendar-item-edit',
        component: () => import('@/components/Calendar/CalendarItemForm'),
        meta: {
            requiresAuth: true,
            havePermission: 'calendar.view',
            matchHeight: true
        }
    },
]