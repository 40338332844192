<template>





  <div class="container-fluid">
    <div class="page-header">
        <div class="row">
          <div class="col-6">
            <h3 class="addExtraPaddingRightForMobile">
              {{ title }}
              <span style="color:#777;font-weight:normal">
              {{ title3 }}
            </span>


            <!-- <i class="fa fa-question-circle" style="color:#60b0f4"></i> -->
            
            <small>{{ title2 }}</small>
            </h3>
          </div>
          <div class="col-6">
            <nav class="pull-right">
              <ol class="breadcrumb bumpUpForMobile">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ $t("menu_dashboard") }}</router-link>
                </li>
                <li v-for="crumb of breadcrumbs" class="breadcrumb-item">
                  <router-link v-if="crumb.route" :to="crumb.route">{{
                      crumb.name
                  }}</router-link>
                  <span v-else>{{ crumb.name }}</span>
                </li>
              </ol>
            </nav>
          </div>
      </div>
    </div>
  </div> 

</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {

    };
  },
  props: {
    title3:{
      default: ''
    },
    newVersion:{
      default: false
    },

    showBack: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    title2: {
      type: String,
      default: () => {
        return "";
      },
    },
    breadcrumbs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {},
  mounted() {
    //this.$store.dispatch('getPandaLogs');
    //  this.$store.dispatch('inbox');
  },
  methods: {},

  components: {},
};
</script>


<style scoped lang="scss">
.innerHeader {
  flex-wrap: wrap;

  div:first-child {
    align-items: stretch;
    flex: 1;
  }
}

.h1Wrap {

  display: flex;
  gap: 10px;
  align-content: center;

  h1 {
    margin: 0;
  }
  i{
    margin-top:10px;
  }
}

.s_btn {
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .h1Wrap {
    width: 100%;
  }

  .page-header-actions {
    margin: 10px 0 0 0;
    display: flex;
    width: 100%;

    button {
      padding: 5px;
      width: auto;

    }

    .s_btn {
      height: auto;
      border-radius: 10px;

      svg {
        width: 100%;
      }
    }
  }
}
</style>
