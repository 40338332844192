<template>
  <div class="calendarView">
    <Modal ref="newItemModal">

      <h2 class="text-center mb-1">
        הוספה ליומן
        <br>
        {{ selectedDateObject | dateOnly }}
      </h2>

      <div class="row">
        <div class="col-sm-12">
          <!--  new item -->
          <div class="create-menu text-center d-flex">
            <a style="cursor:pointer"
               @click.prevent="handleNewItemClick(item.type)"
               v-for="item of newItemsOptions"
               v-bind:key="item.name"
               class="s-box flex-fill py-3 my-4 mx-2">
              <svg :class="item.icon">
                <use :xlink:href="'#' + item.icon"></use>
              </svg>
              {{ item.name }}
            </a>
          </div>
        </div>

        <!-- new note -->
        <!-- <h3 class="m-3">הערות ליומן</h3>
        <div class="col-sm-12 col-md-12">
          <div class="d-flex">
            <div class="flex-fill">
              <div class="s-badge" v-for="n in notes" :key="n.id">
                <i class="fa fa-trash mx-3" @click="deleteNote(n)"></i> {{ n.title }} 
              </div>
            </div>
            <div>
              <form @submit.prevent="addClaendarItemNote" class="d-flex items-center gap-3">
                <FormInput type="textarea" v-model="newNote.title" class="flex-fill" :placeholder="$t('notes')"></FormInput>
                <div class=""><button type="submit" class="s_btn ">{{ $t('save') }}</button></div>
              </form>
            </div>
          </div>
        </div> -->
      </div>


      <!-- <h3 class="m-3">לוח זמנים</h3> -->
      <!-- <div style="height:300px;overflow-y: scroll">
        <table class="seatupTable times" v-if="selectedDate">
          <tr>
            <th>{{ $t('hour') }}</th>
            <th>{{ $t('event') }}</th>
          </tr>
          <tr v-for="hour in 24" v-bind:key="hour" @click="selectedTime = hour - 1" :class="selectedTime == hour - 1 ? 'selected' : ''">
            <td><strong>{{ hour - 1 | padZeros(2) }}:00</strong></td>
            <td>
              <table>
                <tr v-for="item of itemsForHour(hour - 1)" v-bind:key="item.name">
                  <td class="text-bold">
                    <strong>{{ item.startDate | hourOnly }}</strong>
                  </td>
                  <td :class="item.classes ? item.classes.join(' ') : ''" @click="calendarItemAction(item)">
                    {{ item.title }}
                  </td>
                </tr>
              </table>
            </td>
            <td></td>
          </tr>

        </table>
      </div> -->
    </Modal>


    <Modal ref="meetingModal">
      <MeetingForm ref="meetingForm"
                   :showHeader="false"></MeetingForm>
      <div class="clear"></div>
    </Modal>

    <Modal ref="infoModal">
      <h3>
        <i class="fa fa-palette" />
        מקרא צבעים
      </h3>
      <div v-html="colorMapInfo()"></div>
    </Modal>

    <button @click="$refs.infoModal.show()"
            class="s-badge"
            v-if="!isPlanup()">
      <i class="fa fa-color"></i>
      <i class="fas fa-palette"></i>
      מקרא צבעים
    </button>

    <calendar-view itemContentHeight="2.4em"
                   :show-times="true"
                   :timeFormatOptions="formatTime"
                   :display-period-uom="displayPeriod"
                   :displayPeriodCount="displayPeriodCount"
                   :show-date="showDate"
                   :items="calendarItems"
                   @click-date="onClickDay"
                   @click-item="onClickEvent"
                   class="theme-default"
                   :style="'height:' + height + '!important'">
      <template #header="{ headerProps }"
                v-if="showHeader">
        <calendar-view-header :header-props="headerProps"
                              @input="setShowDate" />
      </template>
      <template #dayContent="h">
        <div v-html="hebrewDateDay(h.day)"
             class="cv-date-heb"></div>
      </template>

    </calendar-view>

    <AddCustomerModal @savedNewCustomer="savedNewCustomerHandler"
                      ref="addCustomerModal"></AddCustomerModal>
    <ActionsForCustomerModal ref="actionsForCustomerModal"></ActionsForCustomerModal>
    <FindCustomerModal ref="findCustomerModal"></FindCustomerModal>
  </div>
</template>

<script>
import { dateToStr, arrToDate } from "../../helpers/date-helper";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import MeetingForm from '@/components/Meeting/MeetingForm';
require("vue-simple-calendar/static/css/default.css");

import axiosV2, { apiRequest } from "../../core/http/axios_api_v2";
import { isPlanup } from '../../Config';
import { mapCalendarItems } from '../../services/calendar/item-mapper';
import AddCustomerModal from "../Customers/AddCustomerModal.vue"
import FindCustomerModal from "../Customers/FindCustomerModal.vue";
import ActionsForCustomerModal from "../Customers/ActionsForCustomerModal.vue";

export default {
  name: "Calendar",
  props: {
    items: {
      type: Array,
      default: [],
    },
    displayPeriod: {
      default: "month",
      type: String,
    },
    displayPeriodCount: {
      default: 1,
      type: Number,
    },
    height: {
      default: '700px',

    },
    eventClick: {
      default: null,

    },
    showHolydays: {
      type: Boolean,
      default: false,

    },
    interactiveItem: {
      type: Boolean,
      default: true,

    },
    showHeader: {
      type: Boolean,
      default: true,

    },
    dateSelectHandler: {
      type: Function,
      default: null,
    },
  },

  computed: {
    todayItems() {
      const todays = this.items.filter(item => {
        return item.startDate.getDate() == this.selectedDateObject.getDate()
      })
      this.notes = todays.filter(x => x.itemType == 'item_N')

      return todays
    },
    calendarItems: function () {
      return this.holydays.concat(this.items)
    },
    newItemsOptions: function () {
      return [



        {
          type: 'event',
          name: 'לקוח חדש',
          icon: 'i_event_tab'
        },
        {
          type: 'meeting',
          name: 'לקוח קיים',
          icon: 'i_meeting_tab'
        }




        // {
        //   type: 'event',
        //   name: this.$t("addEvent"),
        //   icon: 'i_event_tab'
        // },
        // {
        //   type: 'meeting',
        //   name: this.$t("Add Meeting"),
        //   icon: 'i_meeting_tab'
        // }
        // ,
        // {
        //   type: 'quatation',
        //   name: this.$t("offer"),
        //   icon: 'i_offer_tab'
        // },
        // {
        //   type: 'reserve',
        //   name: 'שריון',
        //   icon: 'i_calender'
        // },
        // {
        //   type: 'option',
        //   name: 'אופציה',
        //   icon: 'i_calender'
        // },
      ];
    },
  },
  data() {
    return {
      notes: [],
      newNote: {
        title: '',
        type: 'N'
      },
      alphabet: {},
      showDate: new Date(),
      selectedDate: "",
      selectedDateObject: null,
      holydays: [],
      hallColors: ['6495ED', '4682B4', '483D8B', '7B68EE', 'B0C4DE'],
      selectedTime: null,
      formatTime: {
        hour: 'numeric', minute: 'numeric',
      }
    };
  },
  watch: {

  },

  /**
   * this section is rtl hack to make calendar work as rtl
   * it will flip all position absolute elements
   */
  updated: function () {
    this.$nextTick(function () {
      this.convertRTL()
    });
  },
  components: {
    CalendarView,
    CalendarViewHeader,
    MeetingForm,
    AddCustomerModal,
    FindCustomerModal,
    ActionsForCustomerModal
  },
  beforeMount() {
    const alphabetArray = Array.from('אבגדהוזחטיכלמנסעפצקרשת')
    let multiplier = 1

    for (let i = 0; i < alphabetArray.length; i++) {
      let section = (i) % 9 + 1
      multiplier = 10 ** parseInt((i) / 9)
      this.alphabet[(section * multiplier)] = alphabetArray[i]
    }

  },
  mounted() {

    this.convertRTL()


    // if (this.showHolydays)
    //   axiosV2.get('misc/holydays', {
    //     headers: {
    //       'Authorization': null
    //     }
    //   })
    //     .then(res => {
    //       this.holydays = res.data
    //     })




  },
  methods: {



    savedNewCustomerHandler(customerId) {

      this.$refs.actionsForCustomerModal.show(customerId, this.selectedDate);

    },


    isPlanup,

    itemsForHour(hour) {
      return this.todayItems.filter(item => item.startDate.getHours() == hour)
    },

    convertRTL() {
      document.querySelectorAll(".cv-item").forEach((el) => {
        if (el.style.left != "auto") {
          let offset = parseInt(this.getCssProperty(el, "left").split("px")[0]) + "px";
          el.style.right = offset;
          el.style.left = "auto";
        }
      });
    },

    addClaendarItemNote() {

      this.newNote.start_date = new Date(this.selectedDateObject)
      this.newNote.start_date.setHours(this.selectedTime)

      apiRequest('post', 'calendar', this.newNote).then(item => {
        this.newNote.title = ''
        this.items.push(mapCalendarItems(item))
        this.items = [...this.items]
        this.$forceUpdate()
      })
    },
    deleteNote(note) {
      const id = note.id.split('_').at(-1)

      apiRequest('delete', `calendar/${id}`, this.note).then(item => {
        this.items = this.items.filter(x => x.id != note.id)
        this.$forceUpdate()
      })
    },

    hebrewDateDay(date) {

      let hebrewDate = new Intl.DateTimeFormat('he-u-ca-hebrew', { month: 'numeric', day: 'numeric' }).format(date)
      let hebrewDay = hebrewDate.split(' ').at(0)
      let units = hebrewDay % 10
      let tens = parseInt(hebrewDay / 10) * 10
      if (hebrewDay.length > 1) {
        return `${this?.alphabet[tens] ?? ''}"${this?.alphabet[units] ?? ''} ${hebrewDate.split(' ').pop()}`
      } else {
        return `${this?.alphabet[units] ?? ''} ${hebrewDate.split(' ').pop()}`
      }
    },
    getCssProperty(el, property) {
      return window.getComputedStyle(el, null).getPropertyValue(property);
    },
    setShowDate(d) {
      this.$store.state.calendarMonth = d
      this.$emit('monthChange', d)
      this.showDate = d;
    },
    handleNewItemClick(itemType) {
      switch (itemType) {
        case 'event':
          // this.$router.push(`/events/add/${this.selectedDate}`)
          this.$refs.newItemModal.hide();
          this.$refs.addCustomerModal.show();
          break;
        case 'meeting':
          // this.$refs.newItemModal.hide()
          // const _d = arrToDate(this.selectedDate.split("-"), "DD-MM-YYYY")
          // if (this.selectedTime)
          //   _d.setHours(this.selectedTime)
          // this.$refs.meetingForm.setDate(_d)
          // this.$refs.meetingModal.show()

          this.$refs.newItemModal.hide();
          this.$refs.findCustomerModal.show();

          break;
        case 'quatation':
          this.$router.push(`/quotation/new?date=${this.selectedDate}`)
          break;
        case 'reserve':
          // this.$router.push({ name: 'calendar-item-create', params: { itemType: 'reserve' }, query: { date: this.selectedDate } })
          this.$router.push(`/quotation/new?date=${this.selectedDate}&type=reserve`)
          break;
        case 'option':
          // this.$router.push({ name: 'calendar-item-create', params: { itemType: 'option' }, query: { date: this.selectedDate } })
          this.$router.push(`/quotation/new?date=${this.selectedDate}&type=option`)
          break;

      }
    },
    onClickEvent(e) {

      if (this.eventClick) {
        this.eventClick(e);
        return
      }
      if (this.interactiveItem)
        this.calendarItemAction(e.originalItem)
    },

    calendarItemAction(item) {

      let itemId = item.id.split('_').at(-1)
      if (item.itemType == 'event') {
        this.$router.push("/event/" + item.class + "/" + itemId);
      }
      else if (item.itemType == 'meeting') {
        this.$router.push("/meeting/" + itemId + '/view');
      }
      else if (item.itemType == 'quotation') {
        this.$router.push("/quotation/" + itemId);
      }
      else if (['item_R', 'item_O'].includes(item.itemType)) {
        this.$router.push({ name: 'calendar-item-edit', params: { itemId: itemId } })
      }
    },
    onClickDay(d) {

      let dateString = dateToStr(d, "YYYY-MM-DD");
      this.$data.selectedDate = dateString;
      this.$data.selectedDateObject = d;

      if (this.dateSelectHandler) {
        this.dateSelectHandler(d)
        return
      }

      this.$refs.newItemModal.show();
      return;

    },

    colorMapInfo() {
      return (
        "<div class='keysButtonWrapper'><ul><li class='wedding'>" +
        this.$t("eventType_wedding") +
        "</li><li class='barMitzvah'>" +
        this.$t("eventType_barMitzvah") +
        "</li><li class='circumcision'>" +
        this.$t("eventType_circumcision") +
        "</li><li class='bussines'>" +
        this.$t("eventType_bussines") +
        "</li><li class='other'>" +
        this.$t("eventType_other") +
        "</li><li class='holiday'>" +
        this.$t("eventType_holiday") +
        "</li><li class='meeting'>" +
        this.$t("eventType_meeting") +
        "</li><li class='quotation'>" +
        this.$t("eventType_quotation") +
        "</li></ul></div>"
      );
    },
  },
};
</script>

<style lang="scss">
$colorMap: (
  wedding:#0d72f5,
  barMitzvah:#9a53f1,
  circumcision:#48c3fc,
  bussines:#475c7f,
  conference :#e09900,
  other:#f8bf46,
  holiday:#ccc,
  meeting:rgb(98, 165, 77),
  quote:#e89002,
  reserve:#e89002,
  option:#e89002,
);

.calendarView {
  height: 200%;

  .seatupTable.times {
    tr {
      cursor: pointer;

      &.selected {
        background: gold;
      }
    }
  }

  .cv-header {
    display: flex;
    flex-direction: row-reverse;
    font-size: 0.8rem;
    ;
  }

  .periodLabel {
    color: #7893C1;
    font-weight: bold;
  }

  .cv-day,
  .cv-weekdays,
  .cv-item {
    direction: rtl;
  }

  .cv-item {
    padding-right: 25px;
    display: flex;
    flex-direction: column-reverse;
    height: 2.4em;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* gap: 5px; */

    &::before {
      font-family: "Font Awesome 5 Free";
      position: absolute;
      right: 3px;
      top: 30%;
    }

    // &.item_R {
    //   background: #e89002;

    //   &::before {
    //     content: '\f02e';
    //   }
    // }

    &.item_N {
      background: white;
      color: black;
      padding: 2px;
    }

    &.type_of_event {
      &::before {
        content: '\f005';
      }
    }

    &.meeting {
      &::before {
        content: '\f2b5';
      }
    }

    &.option {
      &::before {
        content: '\3f';
      }
    }

    &.reserve {
      &::before {
        content: '🛡️';
      }
    }

    .startTime {
      color: white;
      font-size: 11px;
      font-weight: normal;
    }
  }

  .cv-header {
    background: #f8f8f8 !important;
    border: 0;
  }

  .cv-header-nav button {
    border: 0;
  }

  .cv-weekdays {
    direction: rtl;
    position: relative
  }

  .cv-item {
    direction: rtl;
    font-size: 15px;
    background: #293b5a;
    color: white;
    border-radius: 0 !important;

  }

  .cv-date-heb {
    font-size: 12px;
    color: #7893C1;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .theme-default .cv-day.today {
    background-color: rgb(185, 235, 255);
  }

  .create-menu {
    a {
      font-weight: bold;
    }

    svg {
      margin: 10px 0;
      height: 60px;
      fill: var(--main-color);
    }
  }

  .cv-wrapper {
    >div {
      overflow-y: hidden;
    }

    @each $type,
    $color in $colorMap {
      .#{$type} {
        background: $color;
      }
    }
  }

}


.keysButtonWrapper {
  border: 1px solid #dddddd;
  background: #fff;
  padding: 10px 5px;

  ul {
    li {
      color: #a5a5a5;
      text-align: right;
      font-size: 0.8235rem;
      padding: 5px;
      padding-right: 30px;
      position: relative;

      &:before {
        content: "";
        width: 11px;
        height: 11px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    @each $type,
    $color in $colorMap {
      li.#{$type} {
        &:before {
          background: $color;
        }
      }
    }



  }
}</style>
