 
<template>
  <div class="position-relative">

    <div class="auto-complete-search-wrapper">
      <input class="autocomplete-input w-100" type="search" v-model="search" v-bind:placeholder="placeholder + '...'" @click="openList" />
      <svg class="box-icon">
        <use xlink:href="#i_search"></use>
      </svg>
    </div>

    <div class="search-results" style="position: absolute" :style="resultsStyle" v-show="results && openResults">

      <button type="button" @click="openResults = false" class="btn-close">
        <svg>
          <use xlink:href="#i_close"></use>
        </svg>
      </button>

      <slot name="resultList" v-bind:results="results">
      <ul>
        <li v-for="result of results" :key="result.id" @click="selectEvent" :data-id="result.id">
          <span v-if="optionFormat" v-html="optionFormat(result)"> </span>
          <span v-else> {{ result.name }} 
            {{  result.type  ? result.type.title : '' }} 
          </span>
        </li>
      </ul>
      </slot>
    </div>


  </div>

</template>

<script>
import  { apiRequest } from "../../core/http/axios_api_v2";
import  { debounce } from "../../helpers/interaction.js";

export default {
  name: "AutoComplete",
  data() {
    return {
      search: "",
      openResults: false,
      results: [],
      
    };
  },
  props: {
    url: {
      required: true,
    },
    resultsStyle: {
      type: String
    },
    showPagination: {
      default: true,
    },
    mode: {
      default: "",
    },
    placeholder: {
      default: "חפש",
    },
    minSearchLength: {
      default: 1,
    },
    optionFormat: {
      default: null,
    },
  },
  watch: {
    search: function () {
      if ( this.search.length >= this.minSearchLength) 
        debounce(this.getData, 1000)()
      
      else {
        //this.results = r.data;
        this.openResults = false;
      }
    },
  },
  mounted() { },

  methods: {

    openList() {
      if (this.results.length == 0 && this.search.length >= this.minSearchLength) {
        this.getData();
        this.openResults = true;
      }
      
    },
    getData() {



      apiRequest('get', this.url + this.search).then((r) => {
        this.results = r?.data || r;
        this.openResults = true;
      });
    },
    selectEvent(ev) {
      const data = this.results.find( (x) => x.id == ev.currentTarget.dataset.id );
      if (!data) return;
      this.$emit("selected", data);
      setTimeout(() => {
        this.openResults = false;
        this.search = "";
      }, 300);
    },
  },
};
</script>

<style scoped lang="scss">
.auto-complete-search-wrapper{
  position: relative;
}
.box-icon {
  position: absolute;
  left: 10px;
  top: 5px;
  z-index: 20;
}
.autocomplete-input {
  border-bottom: 1px solid var(--main-color);
  padding: 5px;
  margin-bottom: 10px;
}

.inputWrap.submitWrap {
  text-align: center;
}

li {
  cursor: pointer;
  padding: 5px 10px 5px 30px;

  &:hover {
    background: lightblue;
  }
}

.search-results {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.73);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.8px);
  -webkit-backdrop-filter: blur(7.8px);
  border: 1px solid rgba(255, 255, 255, 0.88);
  z-index: 999;
}

.btn-close {
  //position: absolute;
  float: left;
  margin: -5px;
  z-index: 999;
  border-radius: 50%;

  svg {
    height: 20px;
    width: 20px;
  }
}
</style>
