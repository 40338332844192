<template>
  <div class="page-main-header">

    <div class="main-header-left">
      <div class="logo-wrapper hideForMobile"
           style="width:235px;text-align: center;">
        <router-link :to="'/'">
          <img src="/static/planup.png">
        </router-link>
      </div>


      <div class="hideForDesktop"
           v-if="$store.state.isLogged"
           style="font-size:20px;padding:12px"
           @click="$emit('toggleMenu')">
        <i class="fa-solid fa-bars"></i>
      </div>





    </div>


    <div class="main-header-center">

      <router-link to="/"
                   class="hideForDesktop">
        <img src="/static/planup.png"
             style="width:100px">
      </router-link>

    </div>



    <div class="hideForDesktop main-header-left"
         v-if="!$store.state.isLogged">

    </div>

    <div class="hideForDesktop main-header-left"
         v-if="$store.state.isLogged"
         style="direction:ltr;user-select: none;">



      <li style="    list-style-type: none;padding: 12px;font-size: 20px;position: relative;top: -2px;"
          @click="$root.$emit('findCustomer', $handlers.customerHandler.goToSearchResult)">
        <i style="position:relative;top:3px;"
           class="fa-solid fa-magnifying-glass"></i>

      </li>

      <li style="    list-style-type: none;padding: 12px;font-size: 20px;position: relative;top: -2px;"
          @click="$emit('openQuickCalendar')">
        <i class="fa-regular fa-calendar"
           style="position:relative;top:2px;"></i>
      </li>





    </div>








    <div class="main-header-right row hideForMobile"
         v-if="$store.state.isLogged">
      <div class="nav-right col">
        <ul class="nav-menus">


          <router-link to="/settings?activeTab=orginizationSettings_googleTab" v-if="this.$store.state.businessSettings.whatsappServer && !$store.state.whatsappStatus.includes('0')">
            <li style="color:#b82e24;position:relative;top:3px"
                class="fa-fade">
              <i class="fa-solid fa-triangle-exclamation"></i>
              <span
                    style="    position: absolute;font-size: 12px;width: 85px;bottom: -21px;left: -31px;text-align: center;line-height: 12px;">
                Whatsapp
                <br>
                מנותק
              </span>
            </li>
          </router-link>


          <li @click="$root.$emit('findCustomer', $handlers.customerHandler.goToSearchResult)">
            <i style="position:relative;top:3px;"
               class="fa-solid fa-magnifying-glass"></i>

          </li>


          <li @click="$emit('openQuickCalendar')">
            <i class="fa-regular fa-calendar"
               style="position:relative;top:2px;"></i>
          </li>

          <li v-if="false"
              @click="toggleLanguage">
            {{ $t('langName') }}
          </li>

          <li @click="showLog = !showLog"
              v-bind:class="{ highlightHeaderButton: showLog }">
            <i style="position:relative;top:3px;"
               class="fa-regular fa-clock"></i>

            <div v-show="showLog"
                 class="card"
                 style="left: 0;top:80px;box-shadow:0 5px 7px 0 rgba(0,0,0,.1); position:absolute;width:300px;border-radius: 0 0 6px 6px;border:solid 1px #eeee;background:white;">

              <div style="background:#eeeeee;text-align:center;font-size:18px;font-weight:600">
                פעולות אחרונות

              </div>


              <div>


                <div v-if="!logs.length"
                     style="text-align: center;">
                  <br>
                  <br>
                  <i class="fa-solid fa-spinner fa-spin"></i>
                  <br>
                  <br>
                  <br>
                </div>


                <div v-else>

                  <div @click="$globals.clickOnItem(log.table, log.entityId)"
                       v-for="log in logs"
                       style="padding: 10px 5px;border-bottom: solid 1px #eeeeee"
                       class="headerLogRow">

                    <div :class="`clickDateModalRow_${log.table}`"
                         style="display:inline-block; margin-left:20px;">
                      {{ $handlers.logHandler.logTableToText(log.table) }}
                    </div>

                    <div style="display:inline-block;margin-left:20px;">
                      {{ log.action ? 'עריכה' : 'יצירה' }}
                    </div>

                    <div style="display:inline-block;">
                      {{ log.value.name }}
                      {{ log.value.title }}
                      {{ log.value.firstName }}
                      {{ log.value.lastName }}

                    </div>

                  </div>

                </div>



              </div>



            </div>

          </li>



          <li>
            <span style="color:#ab8ce4;font-weight:bold;">{{ getUserName }}</span>
          </li>


        </ul>
      </div>
    </div>




    <ul style="display:none"
        v-if="false"
        class="headernav-ul">
      <li class="l_user">
        <div class="dropdown"
             style="float: right">
          <button class="selectBtn"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <!--<span class="profileImg"><img src="/static/images/user.png" alt=""></span>-->
            <span class="badge"
                  style="color: white; background: #293b5a; margin: 0 3px"
                  v-can:messages="'view'">
              {{ messageCount }}
            </span>
            <span>{{ $t("hello") }} {{ getUserName }}</span>
            <svg class="i_select">
              <use xlink:href="#i_select"></use>
            </svg>
          </button>
          <div class="dropdown-menu">
            <ul>
              <li v-can:messages="'view'">
                <router-link to="/messages">
                  <svg class="i_user">
                    <use xlink:href="#i_message"></use>
                  </svg>
                  <span>{{ messageCount }} {{ $t("messages") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/profile">
                  <svg class="i_user">
                    <use xlink:href="#i_user"></use>
                  </svg>
                  <span>{{ $t("myProfile") }}</span>
                </router-link>
              </li>
              <li>
                <a href="#"
                   @click.prevent="logOff">
                  <svg class="i_logout">
                    <use xlink:href="#i_logout"></use>
                  </svg>
                  <span>{{ $t("logoff") }}</span>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </li>


      <li class="l_lang">
        <LanguageSelector></LanguageSelector>
      </li>


      <li class="l_info">
        <div class="dropdown">
          <button class="selectBtn"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <svg class="i_info">
              <use xlink:href="#i_info"></use>
            </svg>
            <span>{{ $t("support") }}</span>
            <svg class="i_select">
              <use xlink:href="#i_select"></use>
            </svg>
          </button>
          <div class="dropdown-menu">
            <ul>
              <li>
                <a href="#"
                   class="trigger"
                   id="contactUs"
                   @click="openContactModal">
                  <svg class="i_contact">
                    <use xlink:href="#i_contact"></use>
                  </svg>
                  <span>{{ $t("contact") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li class="l_info">
        <div class="dropdown">
          <button @click="openVideo"
                  class="selectBtn"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <svg class="i_help">
              <use xlink:href="#i_help"></use>
            </svg>
            <span>{{ $t("getStarted") }}</span>
          </button>
        </div>
      </li>

    </ul>

  </div>
</template>

<script>
import axiosV2 from "../../core/http/axios_api_v2";
import LanguageSelector from "./LanguageSelector";
import Vue from 'vue'

export default {
  name: "HeaderNav",
  data() {
    return {
      showLog: false,
      logs: [],
      // pandaNotifications: []
    };
  },
  sockets: {

  },
  watch: {
    showLog: function (val) {

      if (val) {
        axiosV2('/tracking-log/?sortField=&sortType=&page=1&perPage=5&searchTerm=').then((response) => {

          this.logs = response.data.rows
        });
      } else {
        this.logs = [];
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    getUserName() {
      return this.user ? this.user.name : "";
    },

    messageCount() {

      let m = this.$store.state.TasksStore.inbox.filter(
        (x) => !x.msg_to_user || x.msg_to_user.status != "completed"
      );
      if (m) return m.length;
      return 0;
    },
  },
  mounted() {
    // this.$socket.on(this.$socket.id, (data) => {
    //   const socketData = data.data
    //   const socketType = data.type

    //   switch (socketType) {
    //     case 'quote_reminder':
    //     this.$store.commit('addMessage', socketData.message)
    //       this.$notify({
    //         title: 'תזכורת לסגור עיסקה',
    //         text: socketData.clientName,
    //         route: '/browse/quotation'
    //       });
    //       break;
    //     case 'message_received':
    //       this.$store.commit('addMessage', socketData)
    //       this.$notify({
    //         title: 'קיבלתם הודעה!',
    //         text: socketData.title,
    //         route: '/browse/quotation'
    //       });
    //       break;
    //   }
    // })
  },
  methods: {
    toggleLanguage() {

      if (Vue.i18n.locale() == 'he') {

        document.body.classList.remove('rtl');
        Vue.i18n.set('en');
        this.$store.dispatch('saveLang', 'en');

      } else {

        document.body.classList.add('rtl');
        Vue.i18n.set('he');
        this.$store.dispatch('saveLang', 'he');

      }

    },
    openVideo() {
      window.open("https://vimeo.com/303576733");
    },
    logOff() {


      //yonso hardcore logoff instead:
      document.cookie = `token=; path=/;SameSite=strict; Secure;`;
      localStorage.removeItem('tenantId');
      window.location.href = `http://${window.location.host}`;


      //previous logoff

      // this.$store
      //   .dispatch("logoff")
      //   .then((response) => {
      //     this.$router.push("/login");
      //   })
      //   .catch((err) => {
      //     console.warn("Failed to logoff - ", err);
      //     this.$router.replace("/login", function () {
      //       setTimeout(function () {
      //         location.reload();
      //       }, 1000);
      //     });
      //   });
    },

    openContactModal() {
      $("#modal-custom").iziModal({
        autoOpen: 1,
      });


    },

    changeMenuState() {
      if (this.$store.state.isKiosk && !this.$store.state.isTablet) return;
      if (this.$store.state.preferences.menuState == "closed") {
        this.$store.dispatch("saveMenuState", "open").then((response) => {
          //on success
          document.body.classList.add("menu_open");
        });
      } else {
        this.$store.dispatch("saveMenuState", "closed").then((response) => {
          //on success
          document.body.classList.remove("menu_open");
        });
      }
    },
  },

  components: {
    LanguageSelector,
  },
};
</script>
<style>
/* yonso fixing css of universal */
.page-main-header .main-header-right .nav-right>ul>li {
  cursor: pointer;
  user-select: none;
}

.rtl .page-main-header .main-header-right .nav-right>ul>li {
  margin-left: 0;
  margin-right: 30px;
}
</style>
