<template>
    <div>



        <Modal title="הוספת הערה"
               ref="commentModal"
               :hideFooter="true">

            <template v-slot:body>
                <div>


                    <div class="mb-3">

                        <label class="form-label">
                            תוכן
                        </label>

                        <textarea class="form-control"
                        v-model="text"
                                  rows="5"></textarea>

                    </div>

                    <div>
                        <label style="user-select: none;cursor:pointer"
                               @click="createTask = !createTask">


                            <span style="position:relative;top:1px;"
                                  class="planup-checkbox planup-checkbox-purple"
                                  :class="{ checked: createTask }">
                                <i class="fa fa-check"></i>
                            </span>


                            צור משימה למעקב
                        </label>
                    </div>






                    <div class="pull-right">
                        <button class="btn btn-primary"
                                @click="save">שמור</button>
                    </div>

                </div>
            </template>

        </Modal>

    </div>
</template>

<script>
export default {
    name: 'CommentModal',
    data() {
        return {
            customerId: null,
            text: '',
            createTask: false

        };
    },
    methods: {
        show(customerId) {
            this.$refs.commentModal.show();
            this.text = '';
            this.customerId = customerId;
        },
        save() {
            this.$refs.commentModal.hide();
            this.$handlers.logHandler.saveComment(this.text, this.customerId, {createTask:this.createTask});
        }


    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
};
</script>

<style>
/* Your component's CSS styles go here */
</style>