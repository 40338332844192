<template>
  <div class="inputWrap dateWrap">
    <label v-if="label" for="eventDate_1">{{ label }}</label>
    <flatPickr ref="flatpickr" class="flatpickr-input" :class="{ disabled }" :disabled="disabled" :value="value" @input="$emit('input', $event )" :config="config"></flatPickr>
  </div>

</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Hebrew } from "flatpickr/dist/l10n/he";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "DatePicker",
  props: {
    inline: {
      Type: Boolean,
      default: false,
    },
    disabled: {
      Type: Boolean,
      default: false,
    },
    enableTime: {
      Type: Boolean,
      default: true,
    },
    label: {
      Type: String,
      default: null,
    },
    onDayCreate:{
      Type: Function,
      default: function(){}
    },
    value: {
      default: () => {
        return new Date();
      },
    },
  },


  components: { flatPickr },
  data() {

    return {
      // Initial value can be a date object as well
      //selectedDate: this.theDate,
      // Get more form https://flatpickr.js.org/options/
      config: {
        // wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.enableTime ? "M j, Y H:i" : "M j, Y",
        dateFormat: 'd/m/Y',
        // altInput: true,
        enableTime: this.enableTime,
        locale: Hebrew, // locale for this instance only
        inline: this.inline,
        onDayCreate: this.onDayCreate
      },
    };
  },
  mounted() { },
  watch: {
    value: function ($date) {
      this.$emit('change', $date)
    }
  },
  methods: {
    redraw(){

      this.$refs.flatpickr.fp.redraw();
    }
  },
};
</script>
