export const ROLES_NUM = {
    Admin: 1,
    Resseler: 2,    //this is seatup user
    Client: 3,
    HallManager: 5, //this is planup user
    HallManagerChild: 6
}

export default ROLES_NUM

