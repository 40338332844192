<template>
  <div class="seatingView innerMainContent" id="dCo" @click="closeOpenTable($event)">
    <PageHeader icon="i_calender" :title="$t(`seating`)" :breadcrumbs="[
      {
        name: $t('event'),
        route: (user.type != 3 ? '/event/' + event.type.class + '/' + event.id : '/'),
      }, { name: $t('seating'), route: '#' },
    ]">
      <template v-slot:action-buttons>

      </template>
    </PageHeader>

    <Modal ref="ModalAddGuest">
      <AddGuestModal :editGuest="guestToEdit" :tableList="eventTableList"></AddGuestModal>
    </Modal>

    <div class="innerHeader" v-if="!$store.state.isTablet">
      <div class="col-xs-12">
        <div class="col-md-5 col-xs-12">
          <div class="d-flex">


            <div class="">
              <div class="totalSeats p-2 rounded">
                <div class="innerTotalSeats">
                  <div>
                    <span class="ulamCapacity">{{ presetStats.seats }}</span>
                    <span>{{ $t("total") }}</span>
                  </div>

                  <div>{{ $t("hallPlaces") }}</div>
                  <div v-if="event && event.guestsSeatingLimit">
                    {{ $t('limit') }}: {{ event.guestsSeatingLimit }}
                    <span style="color:aqua;font-size: 16px;">+ {{ presetStats.reserved }} {{ $t('reserve') }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="mr-2 s-box p-2 d-flex justify-center items-center rounded" v-if="event.reservePercentage > 0">

              <div class="innerTotalSeats text-center">
                <h3>{{ presetStats.reserved }}
                  {{ $t('outof') }}
                  {{ Math.floor((event.guestsSeatingLimit * event.reservePercentage) / 100) }}
                  <br>
                  {{ $t("seats") }} {{ $t("reserve") }}
                </h3>
              </div>

            </div>

            <div class="mx-2">
              <div class="innerBox precentageWrap seatedPlaces rounded">
                <div class="circlePrecWrap">
                  <svg viewBox="0 0 36 36" class="circular-chart">
                    <path class="circle-bg" d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <path class="circle" stroke-dasharray="0, 100" v-bind:stroke-dasharray="
                      (isNaN(
                        isNaN(
                          Math.round(
                            (presetStats.taken / presetStats.seats) * 100
                          )
                        )
                          ? 0
                          : Math.round(
                            (presetStats.taken / presetStats.seats) * 100
                          )
                      )
                        ? 0
                        : isNaN(
                          Math.round(
                            (presetStats.taken / presetStats.seats) * 100
                          )
                        )
                          ? 0
                          : Math.round(
                            (presetStats.taken / presetStats.seats) * 100
                          )) + ', 100'
                    " d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <text x="18" y="20.35" class="percentage">
                      {{
                        isNaN(
                          Math.round(
                            (presetStats.taken / presetStats.seats) * 100
                          )
                        )
                        ? 0
                        : Math.round(
                          (presetStats.taken / presetStats.seats) * 100
                        )
                      }}%
                    </text>
                  </svg>
                </div>
                <div class="precentageTxt">
                  <div class="precentageIcon">
                    <span class="mainIcon"></span> <span></span>
                  </div>
                  <div class="placesNum">{{ presetStats.taken }}</div>
                  <div class="seatedStatus">{{ $t("taken") }}</div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="innerBox precentageWrap unSeatedPlaces rounded">
                <div class="circlePrecWrap">
                  <svg viewBox="0 0 36 36" class="circular-chart">
                    <path class="circle-bg" d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <path class="circle" stroke-dasharray="0, 100" v-bind:stroke-dasharray="
                      (isNaN(
                        isNaN(Math.round((presetStats.available / presetStats.seats) * 100)) ? 0
                          : Math.round((presetStats.available / presetStats.seats) * 100))
                        ? 0 : isNaN(Math.round((presetStats.available / presetStats.seats) * 100))
                          ? 0 : Math.round((presetStats.available / presetStats.seats) * 100)) + ', 100'
                    " d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <text x="18" y="20.35" class="percentage">
                      {{ isNaN(Math.round((presetStats.available / presetStats.seats) * 100)) ? 0 : Math.round((presetStats.available / presetStats.seats) * 100) }}%
                    </text>
                  </svg>
                </div>
                <div class="precentageTxt">
                  <div class="precentageIcon">
                    <span class="mainIcon"></span> <span></span>
                  </div>
                  <div class="placesNum">{{ presetStats.available }} </div>
                  <div class="seatedStatus">{{ $t("available") }}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>

    <div class="bottomPart" v-if="!showLoader">
      <div class="clearfix">
        <div v-if="$store.state.isTablet">

          <h3>
            <span>{{ $t("currentArrivalStatus") }}</span>
          </h3>
          <div>
            <ul>
              <li>
                <strong>{{ arrived }}</strong> {{ $t("arrived") }}
              </li>
              <li>
                <strong>{{ seated }}</strong> {{ $t("seated") }}
              </li>
              <li>
                <strong>{{ percentage }}%</strong> {{ $t("attendance") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="innerBox guestFilter" v-if="!$store.state.isTablet">
            <div class="col-xs-3">
              <div class="statusBar d-flex">
                <button v-bind:title="$t('willCome')" type="button" @click.prevent="setStatusFilter('coming')" id="arrive" class="statusBtn" v-bind:class="statusFilter == 'coming' ? 'active' : ''" v-tippy>
                  <svg class="draggableGuestIcon i_arriving">
                    <use xlink:href="#i_arriving"></use>
                  </svg>
                  <span style="display: block; line-height: 20px"><strong>{{ guests.coming }}</strong></span>
                </button>
                <button v-bind:title="$t('notComing')" type="button" @click.prevent="setStatusFilter('notComing')" id="notArriving" class="statusBtn" v-bind:class="statusFilter == 'notComing' ? 'active' : ''" v-tippy>
                  <svg class="draggableGuestIcon i_notArriving">
                    <use xlink:href="#i_notArriving"></use>
                  </svg>
                  <span style="display: block; line-height: 20px"><strong>{{ guests.notComing }}</strong></span>
                </button>
                <button v-bind:title="$t('mayCome')" type="button" @click.prevent="setStatusFilter('maybeComing')" id="maybeArriving" class="statusBtn" v-bind:class="statusFilter == 'maybeComing' ? 'active' : ''" v-tippy>
                  <svg class="draggableGuestIcon i_maybeArriving">
                    <use xlink:href="#i_maybeArriving"></use>
                  </svg>
                  <span style="display: block; line-height: 20px"><strong>{{ guests.maybe }}</strong></span>
                </button>
                <button v-bind:title="$t('notResponded')" type="button" @click.prevent="setStatusFilter('notResponded')" id="didentAnswer" class="statusBtn" v-bind:class="statusFilter == 'notResponded' ? 'active' : ''" v-tippy>
                  <svg class="draggableGuestIcon i_didentAnswer">
                    <use xlink:href="#i_didentAnswer"></use>
                  </svg>
                  <span style="display: block; line-height: 20px"><strong>{{ guests.notResponded }}</strong></span>
                </button>

                <button type="button" @click.prevent="setStatusFilter('all')" id="all" class="statusBtn flex-fill" v-bind:class="statusFilter == 'all' ? 'active' : ''">
                  <span>{{ $t("all") }}
                    <span style="display: block; line-height: 20px"><strong>{{
                      guests.coming +
                      guests.notComing +
                      guests.maybe +
                      guests.notResponded
                    }}</strong></span>
                  </span>
                </button>
              </div>
            </div>
            <div class="col-xs-3">
              <div class="inputWrap searchWrapper">
                <input type="search" v-model="openSearch" v-bind:placeholder="$t('searchGuestBy') + '...'" />
                <button class="searchEvent">
                  <svg>
                    <use xlink:href="#i_search"></use>
                  </svg>
                  <span class="sr-only">{{ $t("searchGuestBy") }}</span>
                </button>
              </div>
            </div>
            <div class="col-xs-3">
              <div class="inputWrap">
                <div class="selectWrap">
                  <select name="seatingStatus" id="seatingStatus" v-model="seatedFilter">
                    <option value="all">
                      {{ `${$t("guestsStatus")}: ${$t("all")}` }}
                    </option>
                    <option value="seated">{{ $t("seated") }}</option>
                    <option value="notSeated">{{ $t("notSeated") }}</option>
                  </select>
                  <svg class="i_select">
                    <use xlink:href="#i_select"></use>
                  </svg>
                </div>
              </div>
            </div>

            <div class="col-xs-3">
              <div class="inputWrap">
                <div class="selectWrap">
                  <select name="chooseGroup" id="chooseGroup" v-model="groupFilter">
                    <option value="all">
                      {{ `${$t("chooseGroup")}: ${$t("all")}` }}
                    </option>
                    <option value="all">{{ $t("all") }}</option>
                    <option v-for="group in $store.state.EventStore.guestGroups" v-bind:value="group">
                      {{ group }}
                    </option>
                  </select>
                  <svg class="i_select">
                    <use xlink:href="#i_select"></use>
                  </svg>
                </div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <div v-if="!$store.state.isTablet" class="col-md-3" v-bind:class="!$store.state.isTablet ? 'sideHeight' : ''">
          <div class="innerBox guestWrap" v-if="!$store.state.isTablet">
            <h2>
              <i class="fa fa-lock mx-1" v-if="isEventLocked(event)"></i>
              {{ $t("guests") }}
              <span class="placeNumbers">(<span class="ulamCapacity">{{ getTotalGuests(true) }}</span>)</span>
            </h2>

            <div>
              <ul v-bind:id="!isLocked ? 'dragablleItems' : ''" class="guestList">
                <DraggableGuest v-for="(guest, key) of getGuests(true)" :presetLocked="event.presetLocked" :key="key" :draggableGuest="guest" @editClick="handleGuestEditClick"></DraggableGuest>
              </ul>
            </div>
            <div class="text-left" style="
                  text-align: left;
                  direction: ltr;
                  font-weight: bold;
                  font-size: 20pt;
                  cursor: pointer;
                ">
              <i class="la la-angle-left" @click="currentPage != getPages ? currentPage++ : false" v-bind:class="currentPage == getPages ? 'not_active' : ''"></i>
              <i v-if="currentPage >= 1">{{ currentPage }}/{{ getPages }}</i>
              <i class="la la-angle-right" v-bind:class="currentPage == 1 ? 'not_active' : ''" @click="currentPage > 1 ? currentPage-- : false" v-if="getPages > 1"></i>
            </div>
          </div>
        </div>
        <div v-bind:class="
          !$store.state.isTablet ? 'sideHeight col-md-8' : 'col-md-12'
        ">
          <div class="d-flex mb-3">
            <button class="s_btn" @click="tableSwap()" v-if="!isLocked">
              <span>{{ $t("tableSwap") }}</span>
            </button>

            <button class="s_btn" @click="toggleTableReserve()" v-if="!isLocked && event.reservePercentage > 0">
              <span>{{ reserveMode ? "סיים סימון רזרבה" : "סמן רזרבה" }}</span>
            </button>
          </div>



          <div id="ulamLayout">
            <div id="zoomContainer">
              <img :src="presetBackground" />
              <div class="grid" id="gridWrap"></div>
            </div>
          </div>


        </div>

      </div>
    </div>
    <div class="bottomPart text-center" v-if="showLoader">
      <rotate-loader style="opacity: 0.6" :color="'#132C43'"></rotate-loader>
    </div>

    <div class="tableGuestList open" v-show="tableGuestListOpen" v-bind:style="tableGuestListStyle" v-if="getSeatsStatusForTable(table.dbID)">
      <div class="tableGuestListH">
        <div>
          <button class="closeBtn" @click="tableGuestListOpen = false">
            <svg>
              <use xlink:href="#i_close"></use>
            </svg>
          </button>
          <h3>
            {{ $t("tableNumber") }}
            <span id="tableNumber">{{ table.tableNum }}</span>
          </h3>
        </div>
        <div>
          <label for="editbleTableName" id="tableName">{{ table.name ? table.name : $t("tableName") }}</label>

          <button class="editName" v-if="!isLocked">
            <svg>
              <use xlink:href="#i_edit"></use>
            </svg>
          </button>

          <div class="editWrap">
            <input @change="savePresetChanges()" id="editbleTableName" name="editbleTableName" type="text" v-model="tableName" v-bind:placeholder="$t('tableName')" />
            <button class="saveNewName" @click.prevent="savePresetChanges()">
              <svg>
                <use xlink:href="#i_longArr"></use>
              </svg>
            </button>
          </div>
        </div>
        <div>
          <span class="tableCapacity">
            <span>{{ $t("total") }}:</span>
            <span id="tableCapacity">{{ table.tableCapcity }}</span>
          </span>
          <span class="tableAvailability">
            <span>{{ $t("available") }}:</span>
            <span id="availablePlaces">{{ table.tableCapcity - getSeatsStatusForTable(table.dbID).total }}</span>
          </span>
          <span class="tableAvailability">
            <span>{{ $t("seated2") }}:</span>
            <span id="seated2">{{ getSeatsStatusForTable(table.dbID).total }}</span>
          </span>

          <span class="tableAvailability">
            <span>{{ $t("arrived") }}:</span>
            <span id="arrived">{{ getSeatsStatusForTable(table.dbID).arrived }}</span>
          </span>

          <span class="tableAvailability">
            <span>{{ $t("reserve") }}:</span>
            <span id="arrived">{{ table.reserveQnt || 0 }}</span>
          </span>
        </div>
      </div>
      <div class="scrollerWrap">
        <ul id="addTable">
          <li v-for="guest of table.guests" v-bind:data-guest-id="guest.id" class="guestItem">
            <span data-status="yes" class="guestIcon">
              <svg class="i_arraiving">
                <use xlink:href="#i_arraiving"></use>
              </svg>
            </span>
            <span class="guestInfo">
              <div class="guestName">{{ guest.fullName }}</div>
              <div class="guestGroup">{{ guest.groupName }}</div>
              <div class="guestTable">
                <svg class="i_table">
                  <use xlink:href="#i_filter"></use>
                </svg>
                <span class="guestAmount" v-if="guest.status.coming > 0"><svg class="i_user">
                    <use xlink:href="#i_user" style="fill: #41b53e"></use>
                  </svg>
                  <span style="color: #41b53e; font-weight: bold">{{
                    guest.status.coming
                  }}</span></span>
                <span class="guestAmount" v-if="guest.status.notComing > 0"><svg class="i_user" style="fill: #bb1b1b">
                    <use xlink:href="#i_user"></use>
                  </svg>
                  <span style="color: #bb1b1b; font-weight: bold">{{
                    guest.status.notComing
                  }}</span></span>
                <span class="guestAmount" v-if="guest.status.maybeComing > 0"><svg class="i_user" style="fill: #faff04">
                    <use xlink:href="#i_user"></use>
                  </svg>
                  <span style="color: #faff04; font-weight: bold">{{
                    guest.status.maybeComing
                  }}</span></span>
                <span class="guestAmount" v-if="guest.status.notResponded > 0">
                  <svg class="i_user">
                    <use xlink:href="#i_user"></use>
                  </svg>
                  <span style="font-weight: bold">{{
                    guest.status.notResponded
                  }}</span></span>

              </div>
            </span>
            <button class="guestEdit" @click="saveGuestChanges(guest.id, false)" v-if="
              !$store.state.isTablet &&
              !isLocked
            ">
              <i class="fa fa-trash"></i>
              <span class="sr-only">{{ $t("editGuest") }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import RotateLoader from "vue-spinner/src/RotateLoader.vue";
import Config from "../../Config";
import DraggableGuest from "./DraggableGuest";
import AddGuestModal from "../Guests/AddGuestModal";
import {
  sumGuestStatus,
  guestPresetCapacity,
} from "../../helpers/guest/status.js";
import { tableUUIDByNumber, tableList, tableExistInEvent, presetDataToDictionary } from "../../helpers/event/tables";
import { isEventLocked } from '../../helpers/event'
import PresetBuilder from "../../classes/preset-builder/PresetBuilder";
import "../../scss/preset/preset.scss";
import "../../scss/preset/seats.scss";

export default {
  name: "Seating",
  components: {
    RotateLoader,
    DraggableGuest,
    AddGuestModal,
  },
  destroyed() {
    this._destroy();
  },
  beforeDestroy() {
    this._destroy();
  },
  data() {
    return {
      reserveMode: false,

      presetStats: {
        seats: 0,
        taken: 0,
        reserve: 0,
      },

      guestToEdit: null,
      tableChangeThrottled: null,
      tableChangeThrottled2: null,
      statusFilter: "all",
      groupFilter: "all",
      tableGuestListOpen: false,
      tableGuestListPos: { top: 0, left: 0 },
      currentPointerPosition: { x: 0, y: 0 },
      dragulaInitiated: false,
      isDragging: false,
      seatedFilter: "all",
      openSearch: "",
      openedTableGuests: [],
      table: { name: "", guests: [], id: 0 },
      presetBuilder: null,
      autofocusRegion: true,
      presetScale: null,
      event: {
        type: {},
        hall: {},
        owner: {},
        preset: {},
        presetData: [],
        guests: [],
        tableNames: {},
      },
      guests: {
        notResponded: 0,
        coming: 0,
        maybe: 0,
        notComing: 0,
        reserve: 0,
      },
      tableName: "",
      showLoader: true,
      ipp: 40,
      currentPage: 1,
      saving: false,
    };
  },

  watch: {
    table: function () {
      if (typeof this.table === "object" && this.table.hasOwnProperty("name"))
        this.tableName = this.table.name;
    },
    event: function () {
      this.setTableGuests();
    },
  },

  computed: {
    isLocked() {

      return (this.presetLocked || this.isEventLocked(this.$store.state.EventStore.event)) && this.$store.state.user.type == 3
    },
    presetBackground: function () {
      return this.event.preset
        ? Config.SETTINGS.MEDIA_URL + this.event.preset.background
        : "";
    },
    tableGuestListStyle: function () {
      return {
        display: "block",
        left: this.tableGuestListPos.left,
        top: this.tableGuestListPos.top,
      };
    },
    eventTableList: function () {
      if (!this.event || !this.event.presetData) return [];
      return tableList(this.event);
    },
    getPages: function () {
      return Math.ceil(this.getGuests().length / this.ipp);
    },

    arrived() {
      let arrived = 0;
      if (
        this.event.hasOwnProperty("id") &&
        this.event.hasOwnProperty("guests") &&
        Array.isArray(this.event.guests)
      ) {
        let sum;

        this.event.guests.forEach((guest) => {
          if (guest.arrived) {
            sum = sumGuestStatus(guest);
            arrived += (sum > 0 ? sum : 1);
          }
        });
      }

      return arrived;
    },

    seated() {
      // console.log(this.presetBuilder)
      let seated = 0;
      let hashTables = presetDataToDictionary(this.event)
      if (
        this.event.hasOwnProperty("id") &&
        this.event.hasOwnProperty("guests") &&
        Array.isArray(this.event.guests)
      ) {
        this.event.guests.forEach((guest) => {
          if (guest.tableId !== "" && hashTables[guest.tableId]) {
            seated += sumGuestStatus(guest);
          }
        });
      }

      return seated;
    },

    percentage() {
      let percentage = parseInt((this.arrived / this.seated) * 100);
      return isNaN(percentage) ? 0 : percentage;
    },
  },

  methods: {
    isEventLocked,
    _destroy() {
      try {
        $("body").off("click.bodyevents");
        $('[id^="item_"] > div').off("mouseenter");
        $('[id^="item_"] > div').off("mouseleave");
        $(".tableWrap").off("click");
        if (typeof $(".scrollerWrap").getNiceScroll === "function")
          $(".scrollerWrap").getNiceScroll().remove();
        if (typeof $("#dragablleItems").getNiceScroll === "function")
          $("#dragablleItems").getNiceScroll().remove();
        $(".tableWrap").off("click");
        this.dragulaInitiated = false;

        if (
          window.hasOwnProperty("_dragula") &&
          window._dragula.hasOwnProperty("destroy") &&
          typeof window._dragula.destroy === "function"
        ) {
          window._dragula.destroy();
        }
      } catch (e) { }
    },
    destroyed() {
      this._destroy();
    },

    toggleTableReserve() {
      this.reserveMode = !this.reserveMode;
    },
    handleGuestEditClick(guest) {
      this.guestToEdit = guest;
      this.$refs.ModalAddGuest.show();
    },

    /**
     * var id = table UUID
     * Return seating status object {} for table id
     */
    getSeatsStatusForTable(id) {
      if (this.presetBuilder) {
        return this.presetBuilder.tablesSeatStatus[id]
      }
    },

    isTableExist(id) {
      return this.event.presetData.some((table) => table.dbID === id);
    },

    getTableName(tableId) {
      let table = "";
      this.event.presetData.some((_table) => {
        if (_table.dbID === tableId) {
          table =
            _table.hasOwnProperty("name") && typeof _table.name !== "undefined"
              ? _table.name
              : "";
          return true;
        }
      });

      return table;
    },
    getGuests(usePaging = false) {
      let _this = this;
      if (!this.event.guests || typeof this.event.guests.filter !== "function")
        return [];
      let guests = this.event.guests.filter((guest) => {
        if (
          _this.statusFilter != "all" &&
          guest.status[_this.statusFilter] <= 0
        )
          return false;

        // convert to table uuid
        let tableNumber = tableUUIDByNumber(this.event, _this.openSearch);

        if (
          _this.openSearch != "" &&
          guest.tableId != tableNumber &&
          !(guest.fullName || "")
            .toString()
            .toLowerCase()
            .includes(_this.openSearch.toString().toLowerCase()) &&
          (guest.phone || "").toString() !==
          _this.openSearch.toString().toLowerCase()
        )
          return false;

        if (_this.groupFilter != "all" && _this.groupFilter != guest.group)
          return false;

        if (_this.seatedFilter !== "all") {
          if (
            _this.seatedFilter == "seated" &&
            _this.isTableExist(guest.tableId)
          ) {
            return true;
          } else {
            return (
              _this.seatedFilter == "notSeated" &&
              (!guest.tableId || guest.tableId == "" || guest.tableId == 0)
            );
          }
        }

        return true;
      });

      if (usePaging) {
        let start = this.ipp * this.currentPage - this.ipp;
        let end = this.ipp * this.currentPage;
        guests = guests.slice(start, end);
      }

      return guests;
    },
    setLayout() {
      this.presetBuilder = new PresetBuilder(
        this.event,
        this.event.guests,
        "#gridWrap",
        {
          showTableActions: false,
          arriveOnlyMode: this.$store.state.isTablet,
          highlightExcessive: true,
          autofocusRegion: this.autofocusRegion,
          excessiveClass: this.$store.state.isTablet ? null : 'yellowHighlight'
        }
      );

      this.presetBuilder.buildHtml()
      this.presetBuilder.drawSeats()

      if(this.presetScale)
        this.presetBuilder.setScale(this.presetScale)

      this.presetScale = this.presetBuilder.scale
      this.autofocusRegion = false
    },
    initJS() {
      let _this = this;
      if (!this.dragulaInitiated) {
        $.getScript("/static/js/dragula.min.js", function () {
          $.getScript("/static/js/pie.min.js", function () {
            $.getScript("/static/js/dependencies.js", function () {
              _this._initJsFunctionallity();
            });
          });
        });
      } else {
        _this._initJsFunctionallity();
      }
    },

    _initJsFunctionallity() {
      this._destroy();

      this.setLayout();

      //initiate dragula, guests seating

      if (!this.dragulaInitiated && !this.$store.state.isTablet) {
        window._dragula = dragula(
          [
            document.getElementById("dragablleItems"),
            document.getElementById("addTable"),
          ],
          {
            copy: (el, source) => {
              return source === document.getElementById("dragablleItems");
            },
            accepts: (el, target) => {
              return target !== document.getElementById("dragablleItems");
            },
            mirrorContainer: document.getElementById("dCo"),
          }
        )
          .on("drag", (e, t) => {
            if (!this.isDragging) {
              this.isDragging = true;
              this.tableGuestListOpen = false;
            }
          })
          .on("drop", (e, t) => {
            if (this.isDragging) {
              setTimeout(() => {

                this.isDragging = false;
                this.saveGuestChanges($(e).data("guest-id"), true);
              }, 200);
            }
          });
        this.dragulaInitiated = true;
      }

      const self = this;

      //set/unset table on hover
      $(".item > div").on("mouseenter mouseleave click", function (e) {
        const dbID = $(this).data("dbid");
        const table = self.event.presetData.find((tbl) => tbl.dbID === dbID);

        switch (e.type) {
          case "mouseenter":
            // set selected table
            if (!self.saving && table !== null && typeof table === "object") {
              self.setTable(table, e.type === "click");
            }

            // clear all selected
            document
              .querySelectorAll(".item > div[data-dbid] > div")
              .forEach((_) => _.classList.remove("active"));

            // mark selected
            let c = document.querySelector(`.item > div[data-dbid="${dbID}"] > div`)
            if (c) c.classList.add("active");
            break;

          case "click":
            if (self.reserveMode) {

              self.$swal({
                title: 'כמות רזרבה בשולחן',
                input: "select",
                inputOptions: [...Array(5).keys()],
                inputPlaceholder: self.$t("choose"),
                showCancelButton: true,
              })
                .then(reserveChoice => {
                  table.reserveQnt = reserveChoice
                  self.save();
                })
              return;
            }

            setTimeout(() => {
              if (e.type === "click") {
                //set guests
                self.table.guests = self.event.guests.filter(
                  (guest) => guest.tableId === dbID
                );

                const x = $(this).parent()[0].getBoundingClientRect().left + $(this)[0].getBoundingClientRect().width / 2 + 7;
                const y = e.pageY - $(".tableGuestList").height();

                self.tableGuestListPos = { top: y, left: x };

                self.tableGuestListOpen = true;

                self.$forceUpdate();

                //todo finish with the guests list change
                //stopped in here while clicking on table!!
              }
            }, 52);

            break;
          case "mouseleave":
            setTimeout(() => { }, 200);
            break;
        }
      });

      $(".editName").on("click", function () {
        $("#editbleTableName").focus();
      });

      $("body").on("click.bodyevents", ".selectedValue", function () {
        $(".statusDropDown").toggleClass("open");
      });

      $("body").on(
        "click.bodyevents",
        ".seatingView .guestCounter span.plus",
        function (e) {
          let value = parseInt(
            $(".seatingView .guestCounter span.value").html()
          );
          value++;
          $(".seatingView .guestCounter span.value").html(value);
        }
      );
      $("body").on(
        "click.bodyevents",
        ".seatingView .guestCounter span.minus",
        function (e) {
          let value = parseInt(
            $(".seatingView .guestCounter span.value").html()
          );
          if (value > 0) {
            value--;
          }

          $(".seatingView .guestCounter span.value").html(value);
        }
      );
    },
    closeOpenTable(e) {
      let fromTableOrPopup = false;

      if (Array.isArray(e.path)) {
        e.path.forEach((_) => {
          if ($(_).hasClass("item")) fromTableOrPopup = true;
          if ($(_).hasClass("tableGuestList")) fromTableOrPopup = true;
        });
      }
      if (!fromTableOrPopup) this.tableGuestListOpen = false;
    },
    setStatusFilter(_) {
      this.statusFilter = _;
    },
    getGuestsPercent(sum) {
      let _ = Math.round((sum / this.getTotalGuests()) * 100);
      return isNaN(_) ? 0 : _;
    },
    getTotalGuests(filtered = false) {
      if (filtered) {
        let total = 0;
        this.getGuests().forEach((guest) => {
          if (this.statusFilter !== "all") {
            total += parseInt(guest.status[this.statusFilter]);
          } else {
            total += parseInt(guest.quantity);
          }
        });
        return total;
      }
      return (
        parseInt(this.guests.coming) +
        parseInt(this.guests.maybe) +
        parseInt(this.guests.notComing) +
        parseInt(this.guests.notResponded)
      );
    },
    calcPresetStats() {
      try {
        this.presetStats = guestPresetCapacity(
          this.event.presetData,
          this.event.guests,
          this.event.resevedSeatingAllowed
        );
        let _a = this.presetStats.seats - this.presetStats.taken // - this.presetStats.reserved;
        this.presetStats.available = _a > 0 ? _a : 0;
      } catch (e) {
        this.presetStats = {
          seats: 0,
          taken: 0,
          reserve: 0,
        };
        console.error(e);
      }

    },

    fetchGuests(forceUpdate = false) {
      this.$store
        .dispatch("getGuests", this.event.id)
        .then(() => {
          this.event.guests = this.$store.getters['getGuests'];
          for (let g in this.event.guests) {
            this.event.guests[g].key = parseInt(g);
          }
          this.calcPresetStats();
          if (forceUpdate) this.$forceUpdate();
          this.showLoader = false;
          this.calculateGuests();
          this.initJS();
        })
        .catch((err) => {
          //err
          console.warn(err);
          this.$router.push("/");
          this.showLoader = false;
        });
    },
    calculateGuests() {
      if (this.event.guests.length > 0) {
        let _this = this;

        this.guests = {
          notResponded: 0,
          coming: 0,
          maybe: 0,
          notComing: 0,
          reserve: 0,
        };

        this.event.guests.forEach((_) => {
          _this.guests.notResponded += !isNaN(parseInt(_.status.notResponded))
            ? parseInt(_.status.notResponded)
            : 0;
          _this.guests.coming += !isNaN(parseInt(_.status.coming))
            ? parseInt(_.status.coming)
            : 0;
          _this.guests.maybe += !isNaN(parseInt(_.status.maybeComing))
            ? parseInt(_.status.maybeComing)
            : 0;
          _this.guests.reserve += !isNaN(parseInt(_.status.reserve))
            ? parseInt(_.status.reserve)
            : 0;
          _this.guests.notComing += !isNaN(parseInt(_.status.notComing))
            ? parseInt(_.status.notComing)
            : 0;
        });
      }
    },
    savePresetChanges() {
      if (
        this.tableName !== "" &&
        typeof this.table === "object" &&
        this.table.name !== this.tableName
      )
        this.table.name = this.tableName;
      this.$store
        .dispatch("updateEventPreset", {
          id: this.event.id,
          presetData: this.event.presetData,
        })
        .then((_) => {
          this.event.presetData = _.presetData;

          this._destroy();
          this.initJS();
        })
        .catch((err) => {
          this.AlertError();
        });
    },

    checkIfPresetFull(guest) {
      const guestQuantity = sumGuestStatus(guest)
      const limit = this.event.guestsSeatingLimit + (this.presetStats.reserved || 0)

      if ((this.event.guestsSeatingLimit !== null
        && !isNaN(this.event.guestsSeatingLimit)
        && this.event.guestsSeatingLimit > 0)
        && this.seated + guestQuantity > limit
      ) {
        console.log('[checkIfPresetFull] limit:', limit, this.presetStats)
        return limit;
      }
      return false
    },
    saveGuestChanges(guestID, add = false) {

      if (this.table.type !== 'table') {
        console.debug('cannot guest assign to props')
        return;
      }
      this.saving = true;
      const tableId = this.table.dbID;
      const table = this.event.presetData.find((tbl) => tbl.dbID === tableId);

      console.log(this.table)
      if (table === null || typeof table === "undefined") {
        this.saving = false;
        return true;
      }

      const guest = this.event.guests.find(_g => _g.id == guestID)
      if (!guest) {
        this.AlertError('אורח לא נמצא')
        return
      }

      const isPresetFull = this.checkIfPresetFull(guest)

      if (add && isPresetFull) {

        this.AlertConfirm(this.$t("guestsSeatingLimitNotification_title"), {
          confirmButtonText: this.$t("okButton"),
          type: "warning",
          text: this.$t("guestsSeatingLimitNotification_text", {
            quantity: isPresetFull,
          }),
        })

        this.fetchGuests();
        this.saving = false;
        return;
      }

      let guestQuantity = sumGuestStatus(guest)
      const isReserved = table.hasOwnProperty("reserved") && table.reserved === true;
      let seatsStatus = this.getSeatsStatusForTable(tableId, true, false)

      if (this.event.strictTables || (isReserved && this.event.resevedSeatingAllowed !== true)) {

        if (add && (isReserved || (typeof table === "object" && (seatsStatus.total + guestQuantity > table.tableCapcity)))) {
          this.AlertError(this.$t(isReserved ? "reservedTableWarning" : "strictTablesWarning"), { type: "warning" })
          this.fetchGuests();
          this.saving = false;
          return;
        }
      }

      // update selected table guests
      this.table.guests = this.event.guests.filter(guest => guest.tableId === tableId);
      this.$forceUpdate();

      // attach table number to gust
      if (add) {
        guest.tableId = tableId.toString();
      } else {
        guest.tableId = "unset";
      }

      this.$store
        .dispatch("updateGuest", {
          id: guest.id,
          tableId: guest.tableId
        })
        .then((r) => { this.fetchGuests(); })
        .catch((err) => {
          this.AlertError();
          setTimeout(function () {
            this.table = { guests: [] };
            setTimeout(function () {
              $('[data-dbid="' + tableId + '"]').click();
            }, 50);
          }, 50);
        });
      this.saving = false;
      return true;

    },

    async setTableGuests() {
      if (typeof this.table.dbID === "undefined") this.openedTableGuests = [];

      if (!this.event.guests || typeof this.event.guests.filter !== "function") {
        this.openedTableGuests = [];
      } else {
        this.openedTableGuests = this.event.guests.filter((_) => {
          if (_.tableId == this.table.dbID) return true;
          return false;
        });
      }
    },
    setTable(table, ignoreTableGuestListOpened = false) {
      this.tableChangeThrottled2 = setTimeout(() => {
        if (!this.tableGuestListOpen || ignoreTableGuestListOpened) {
          this.tableName = table.name;
          if (table === "initial") {
            this.table = { name: "", guests: [], id: 0 };
          } else {
            this.table = table;
          }
        }
        clearTimeout(this.tableChangeThrottled2);
      }, 0.1);
    },

    tableSwap() {
      let tables = {};
      this.event.presetData
        .sort((a, b) => a.tableNum - b.tableNum)
        .filter((table) => table.tableNum !== "")
        .forEach((_) => {
          tables[_.dbID] = _.tableNum;
        });
      //table 1 select
      this.$swal({
        title: this.$t("selectFirstTable"),
        input: "select",
        inputOptions: tables,
        inputPlaceholder: this.$t("choose"),
        showCancelButton: true,
      }).then((firsttableId) => {
        if (firsttableId !== "") {
          tables = {};


          this.event.presetData.forEach((_) => {
            if (_.dbID !== firsttableId) tables[_.dbID] = _.tableNum;
          });

          this.$swal({
            title: this.$t("selectSecondTable"),
            input: "select",
            inputOptions: tables,
            inputPlaceholder: this.$t("choose"),
            showCancelButton: true,
          }).then((secondtableId) => {


            if (secondtableId !== "") {
              this.AlertConfirm(this.$t("tablesSwapPrompt"), {
                text: this.$t("unrevetableOperation"),
                type: "warning",
                showCancelButton: true,
              })
                .then((result) => {

                  if (this.presetBuilder.getItem(firsttableId).reserved || this.presetBuilder.getItem(secondtableId).reserved) {
                    this.AlertError('אי אפשר להחליף בין שולחנות רזרבה')
                    return
                  }
                  if (result === true) {

                    this.event.presetData.filter((tbl) => {
                      if (tbl.dbID === firsttableId) tbl.dbID = "first";
                      if (tbl.dbID === secondtableId) tbl.dbID = "second";
                    });

                    this.event.presetData.filter((tbl) => {
                      if (tbl.dbID === "first") tbl.dbID = secondtableId;
                      if (tbl.dbID === "second") tbl.dbID = firsttableId;
                    });

                    this.save();
                  }
                })
            }
          });
        }
      });
    },

    save() {
      this.$store
        .dispatch("updateEventPreset", {
          id: this.event.id,
          presetData: this.event.presetData,
        })
        .then((_) => {
          this.setLayout({ elements: this.event.presetData });
          this._initJsFunctionallity();
          this.calcPresetStats();
          this.$forceUpdate();
        })
        .catch((err) => {
          this.AlertError();
        });
    },

    onMouseUpdate(e) {
      this.currentPointerPosition.x = e.pageX;
      this.currentPointerPosition.y = e.pageY;
    },
  },
  beforeMount() {
    this.showLoader = true;
    let eventID = this.$router.currentRoute.params.eventID;
    if (typeof eventID === "undefined" || !eventID) this.$router.push("/");

    this.$store
      .dispatch("getEvent", eventID)
      .then((response) => {
        //success
        let data = response;
        data.presetLocked = data.presetLocked === 1;
        this.event = data;
        this.fetchGuests();
      })
      .catch((err) => {
        //err
        console.warn(err);
        this.$router.push("/");
        return;
      });
    this.$store
      .dispatch("getGuestGroupList", eventID)
      .then((response) => { })
      .catch((err) => {
        //err
        console.warn(err);
        this.showLoader = false;
      });
  },
  mounted() {
    try {
      $("#dragablleItems").getNiceScroll().remove();
    } catch (e) { }
    if (typeof $("#dragablleItems").niceScroll !== "function") {
      $.getScript("/static/js/niceScroll.min.js", function () {
        setTimeout(function () {
          $("#dragablleItems").niceScroll();
        }, 500);
      });
    } else {
      setTimeout(function () {
        $("#dragablleItems").niceScroll();
      }, 500);
    }
  },
};
</script>
<style>
.seatingView h2 {
  color: #7893c1;
  font-size: 1.706rem;
  font-weight: 700;
}

.seatingView h3 {
  color: #293b5a;
  font-weight: 700;
  font-size: 1.059rem;
}

.seatingView .tableAvailability {
  margin: 0 5px;
}

.seatingView .editEventActions {
  float: left;
  margin-top: 6px;
}

.seatingView .eventInfoWrapper {
  padding: 20px 30px;
}

.seatingView .eventInfoWrapper h2 svg {
  display: inline-block;
  vertical-align: middle;
  width: 52px;
  height: 52px;
  fill: #293b5a;
  margin-left: 10px;
}

.seatingView .eventInfoWrapper h2 span {
  display: inline-block;
  vertical-align: middle;
}

.seatingView .eventInfoWrapper h2 span .date {
  color: #293b5a;
  font-size: 1.059rem;
  font-weight: 400;
}

.seatingView .mainInfo {
  margin: 20px 0;
}

.seatingView .mainInfo li {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.059rem;
}

.seatingView .mainInfo li+li {
  margin-right: 15px;
}

.seatingView .mainInfo li svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  fill: #293b5a;
}

.seatingView .mainInfo li svg.i_location {
  width: 28px;
  height: 28px;
}

.seatingView .mainInfo li svg.i_users {
  width: 40px;
  height: 30px;
}

.seatingView .mainInfo li span {
  display: inline-block;
  vertical-align: middle;
}

.seatingView .mainInfo li span.liTitle {
  font-weight: 700;
  color: #7791be;
}

.seatingView .moreInfo {
  background: #f0f0f0;
  padding: 15px;
}

.seatingView .moreInfo li {
  display: inline-block;
  vertical-align: top;
}

.seatingView .moreInfo li h3 {
  margin-bottom: 10px;
}

.seatingView .moreInfo li+li {
  margin-right: 20px;
}

.seatingView .bottomPart {
  float: right;
  width: 100%;
}

.seatingView .placeNumbers {
  font-weight: 400;
}

.seatingView .guestWrap {
  padding: 15px;
}

.seatingView .guestWrap h2 {
  margin-bottom: 20px;
}

.seatingView .guestWrap h2 svg {
  display: inline-block;
  vertical-align: middle;
  fill: #ff0000;
  width: 22px;
  height: 22px;
  margin-right: 15px;
}

.seatingView .guestWrap .selectWrap {
  width: 250px;
}

.seatingView .guestWrap .selectWrap select {
  background: #fff;
  color: #293b5a;
  font-size: 0.8824rem;
}

.seatingView .guestWrap label {
  color: #293b5a;
  font-weight: 700;
  font-size: 1.059rem;
}

.seatingView .totalSeats {
  width: 100%;
  height: 100%;
  background: hsla(218, 37%, 26%, 1);

  background: linear-gradient(90deg, hsla(218, 37%, 26%, 1) 0%, hsla(218, 38%, 63%, 1) 60%);

  background: -moz-linear-gradient(90deg, hsla(218, 37%, 26%, 1) 0%, hsla(218, 38%, 63%, 1) 60%);

  background: -webkit-linear-gradient(90deg, hsla(218, 37%, 26%, 1) 0%, hsla(218, 38%, 63%, 1) 60%);

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#293B5A", endColorstr="#7B96C4", GradientType=1);
  text-align: center;
  color: #cdcdcd;
  font-size: 1.118rem;
  padding: 15px 0;
}

.seatingView .innerTotalSeats {
  display: inline-block;
  text-align: right;
}

.seatingView .totalSeats .ulamCapacity {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  font-style: italic;
  margin-left: 5px;
}

.seatingView .totalSeats .ulamCapacity+span {
  font-style: italic;
  font-size: 0.9412rem;
}



.seatingView .item {
  display: inline-block;
  padding: 20px;
  position: relative;
  width: 32%;
  text-align: center;
}

#list-1 .item {
  padding: 10px;
}

.seatingView .item .imgWrap {
  margin-bottom: 20px;
}

.seatingView .item .imgWrap img {
  max-width: 100%;
}

#gridWrap {
  position: relative;
}

#gridWrap .item {
  width: auto;
  position: absolute;
  padding: 0;
}

.seatingView .seat {
  width: 10px;
  height: 8px;
  border: 1px solid #000;
  border-radius: 2px;
  display: block;
  background: #fff;
}

.seatingView .circlePrecWrap {
  width: 76px;
  height: 76px;
  display: inline-block;
}

.seatingView .precentageTxt {
  display: inline-block;
  padding-right: 5px;
  text-align: right;
}

.seatingView .circlePrecWrap .circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 1.8;
}

.seatingView .circlePrecWrap .circle {
  fill: none;

  stroke-width: 1.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.seatingView .seatedPlaces .circle {
  stroke: #47ce41;
}

.seatingView .unSeatedPlaces .circle {
  stroke: #293b5a;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.seatingView .circlePrecWrap .percentage {
  fill: #626262;
  font-style: italic;
  font-size: 0.5rem;
  text-anchor: middle;
}

.seatingView .precentageWrap {
  padding: 15px;
  text-align: center;
}

.seatingView .precentageIcon {
  position: relative;
  margin-right: 5px;
}

.seatingView .precentageIcon .mainIcon {
  width: 18px;
  height: 16px;
  border-radius: 5px;
  border: 1px solid #fff;
  display: inline-block;
  z-index: 1;
  position: relative;
}

.seatingView .precentageIcon span:not(.mainIcon) {
  width: 24px;
  height: 14px;
  position: absolute;
  top: -1px;
  right: -3px;
  border-radius: 5px 5px 0px 0px;
}

.seatingView .seatedPlaces .precentageIcon span:not(.mainIcon),
.seatingView .seatedPlaces .precentageIcon .mainIcon {
  background: #47ce41;
}

.seatingView .unSeatedPlaces .precentageIcon span:not(.mainIcon),
.seatingView .unSeatedPlaces .precentageIcon .mainIcon {
  background: #293b5a;
}

.seatingView .placesNum {
  color: #4c4c4c;
  font-weight: bold;
  font-size: 1.412rem;
  line-height: 20px;
}

.seatingView .seatedStatus {
  color: #9b9b9b;
  font-size: 1.059rem;
}

.seatingView .rectangleTable .seat,
.seatingView .squareTable .seat {
  display: inline-block;
  position: relative;
}

.seatingView .rectangleTable .seat:before,
.seatingView .squareTable .seat:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background: #fff;
  border: 1px solid #000;
  border-radius: 2px;
}

.seatingView .rectangleTable .side1 .seat,
.seatingView .squareTable .side1 .seat {
  vertical-align: top;
}

.seatingView .rectangleTable .side3 .seat,
.seatingView .squareTable .side3 .seat {
  vertical-align: top;
}

.seatingView .rectangleTable .side2 .seat,
.seatingView .rectangleTable .side0 .seat,
.seatingView .squareTable .side2 .seat,
.seatingView .squareTable .side0 .seat {
  width: 8px;
  height: 10px;
  display: block;
}

.seatingView .rectangleTable .side0 .seat:before,
.seatingView .squareTable .side0 .seat:before {
  left: -2px;
  top: 1px;
}

.seatingView .rectangleTable .side1 .seat:before,
.seatingView .squareTable .side1 .seat:before {
  left: 1px;
  top: -2px;
}

.seatingView .rectangleTable .side2 .seat:before,
.seatingView .squareTable .side2 .seat:before {
  top: 1px;
}

.seatingView .rectangleTable .side3 .seat:before,
.seatingView .squareTable .side3 .seat:before {
  bottom: -2px;
  right: 1px;
}

.seatingView .rectangleTable .side0 .seat+.seat,
.seatingView .squareTable .side0 .seat+.seat {
  margin-top: 3px;
}

.seatingView .rectangleTable .side1 .seat+.seat,
.seatingView .squareTable .side1 .seat+.seat {
  margin-right: 3px;
}

.seatingView .rectangleTable .side2 .seat+.seat,
.seatingView .squareTable .side2 .seat+.seat {
  margin-top: 3px;
}

.seatingView .rectangleTable .side3 .seat+.seat,
.seatingView .squareTable .side3 .seat+.seat {
  margin-right: 3px;
}

.seatingView .rectangleTable .tableSide,
.seatingView .squareTable .tableSide {
  position: absolute;
  text-align: center;
}

.seatingView .rectangleTable .side0,
.seatingView .squareTable .side0 {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

.seatingView .rectangleTable .side1,
.seatingView .squareTable .side1 {
  top: 100%;
  right: 50%;
  transform: translate(50%, 0);
  width: calc(100%);
  height: 8px;
}

.seatingView .rectangleTable .side2,
.seatingView .squareTable .side2 {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
}

.seatingView .rectangleTable .side3,
.seatingView .squareTable .side3 {
  bottom: 100%;
  right: 50%;
  transform: translate(50%, 0);
  width: calc(100%);
  height: 8px;
}

.seatingView .itemLabel {
  margin-top: 15px;
}

.seatingView .guestFilter {
  margin-bottom: 23px;
  padding: 15px;
}

.seatingView .guestFilter input,
.seatingView .guestFilter select {
  background: #fff;
  color: #555555;
  height: 43px;
  line-height: 43px;
  font-size: 0.8235rem;
}

.seatingView .guestFilter .inputWrap.searchWrapper input {
  padding-left: 40px;
}

.seatingView .guestFilter .inputWrap.searchWrapper .searchEvent {
  position: absolute;
  top: calc(50% - 10px);
  left: 20px;
}

.seatingView .guestFilter .inputWrap.searchWrapper .searchEvent svg {
  fill: #293b5a;
  width: 18px;
  height: 20px;
  display: block;
}

.seatingView .guestFilter .inputWrap.searchWrapper input[type="search"]::-webkit-input-placeholder {
  color: #adadad;
  font-style: italic;
  font-size: 0.8824rem;
}

.seatingView .guestFilter .inputWrap.searchWrapper input[type="search"]:-moz-placeholder {
  color: #adadad;
  font-style: italic;
  font-size: 0.8824rem;
}

.seatingView .statusBar {
  width: 100%;
  height: 43px;
  line-height: 40px;
  border-radius: 27px;
  border: 1px solid #bfc1c5;
}

.seatingView .statusBtn {
  float: right;
  width: 16.6%;
  text-align: center;
  padding: 0 5px;
}

#all span {
  color: #293b5a;
  font-weight: 700;
}

#all {
  border-radius: 27px 0 0 27px;
}

.seatingView .statusBtn svg {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.seatingView .statusBtn span {
  color: #555555;
  font-size: 0.7rem;
  display: inline-block;
  vertical-align: middle;
}

svg.i_arraiving {
  fill: #41b53e;
}

svg.i_notArraiving {
  fill: #bb1b1b;
}

svg.i_maybeArraiving {
  fill: #e49e9e;
}

svg.i_didentAnswer {
  fill: #656565;
}

.seatingView .guestFilter .col-lg-6 {
  margin-top: 15px;
}

.seatingView .guestFilter .inputWrap>label {
  color: #293b5a;
  font-size: 0.8235rem;
  margin-bottom: 7px;
  font-weight: 700;
}

.seatingView .guestItem .guestIcon,
.seatingView .guestItem .guestInfo {
  display: inline-block;
  vertical-align: top;
}

.seatingView .guestItem .guestIcon svg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
  margin-left: 5px;
}

.seatingView .guestName {
  color: #293b5a;
  font-weight: 700;
  font-size: 1.059rem;
}

.seatingView .guestGroup {
  color: #7994c2;
  font-size: 0.7647rem;
}

.seatingView .guestTable {
  margin-top: 5px;
}

.seatingView .guestTable .i_table {
  width: 16px;
  height: 15px;
  fill: #293b5a;
  vertical-align: middle;
  display: inline-block;
}

.seatingView .guestTable .tableNum:after {
  content: "|";
  margin-right: 5px;
}

.seatingView .guestTable .tableNum,
.seatingView .guestTable .tableName {
  vertical-align: middle;
  display: inline-block;
  color: #6f6f6f;
  font-size: 0.7647rem;
}

.seatingView .guestAmount {
  vertical-align: middle;
  display: inline-block;
  margin-right: 20px;
}

.seatingView .guestAmount .i_user {
  width: 13px;
  height: 15px;
  fill: #293b5a;
  vertical-align: middle;
  display: inline-block;
}

.seatingView .guestAmount span {
  vertical-align: middle;
  display: inline-block;
  color: #6f6f6f;
  font-size: 0.7647rem;
}

.seatingView .guestEdit {
  float: left;
}

.seatingView .guestEdit svg.editGuestBtn {
  width: 16px;
  height: 16px;
  fill: #293b5a;
}

.seatingView .guestEdit svg.deleteGuestBtn {
  width: 11px;
  height: 11px;
  fill: #7792bf;
}

.seatingView .guestList {
  max-height: 800px;
  height: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

.seatingView .guestList.musk .guestItem:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #50668c;
}

.seatingView .guestItem {
  border-bottom: 1px solid #293b5a;
  padding: 10px;
  background: #f2f2f2;
  z-index: 0;
}

.seatingView .guestList {
  max-height: 585px;
}

.seatingView .guestList li:nth-child(odd) {
  background: #e7e7e7;
}

#dragablleItems .guestItem {
  cursor: move;
}

.seatingView .gu-mirror,
#dragablleItems .guestItem:hover {
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
  z-index: 1;
  position: relative;
}

.seatingView .gu-mirror {
  opacity: 1;
  list-style: none;
}

.seatingView .gu-mirror .guestEdit svg.deleteGuestBtn,
.seatingView .guestList .guestEdit svg.deleteGuestBtn {
  display: none;
}

#addTable .guestEdit svg.editGuestBtn {
  display: none;
}

.seatingView .scrollerWrap {
  overflow-y: scroll !important;
  scroll-behavior: smooth;
  /* height: 500px !important; */
}

.seatingView .mCS-dir-rtl>.mCSB_inside>.mCSB_scrollTools,
.mCS-dir-rtl>.mCSB_outside+.mCSB_scrollTools {
  right: 0;
  left: auto;
}

.seatingView .mCS-dir-rtl:not(.mCS_no_scrollbar)>.mCSB_inside>.mCSB_container {
  margin-right: 20px;
  margin-left: 0px;
}

.seatingView .mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #f0f0f0;
}

.seatingView .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #7a94c2;
}

.seatingView .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.seatingView .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #7a94c2;
}

.seatingView .scrollerWrap {
  height: calc(100% - 59px);
}

.seatingView .sideHeight {
  min-height: 795px;
}

.seatingView .tableGuestList {
  width: 325px;
  height: 285px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.53);
  background: #ffffff;
  position: absolute;
  transform: translateX(-50%);
  z-index: 999;
  display: none;
  top: 0px;
  left: 0px;
}

.seatingView .tableGuestList:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  top: 400px;
  right: calc(50% - 4px);
  z-index: 1;
}

.seatingView .tableGuestList:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #dddddd;
  position: absolute;
  top: 400px;
  right: calc(50% - 6px);
}

.seatingView .tableGuestListH {
  padding: 10px 10px 5px;
  float: right;
  width: 100%;
  border-bottom: 1px solid #293b5a;
  text-align: right;
}

.seatingView .tableGuestList .closeBtn {
  float: left;
}

.seatingView .tableGuestList .closeBtn svg {
  width: 11px;
  height: 11px;
  fill: #293b5a;
  display: block;
}

.seatingView .tableGuestList h3 {
  float: right;
  font-size: 0.8824rem;
  font-weight: 700;
}

.seatingView .tableGuestListH>div {
  float: right;
  width: 100%;
  line-height: 16px;
}

.seatingView .tableGuestListH label {
  margin-left: 7px;
  color: #7a94c2;
  font-size: 0.7059rem;
}

.seatingView .tableGuestList.open {
  display: block;
  top: 33% !important;
  left: 50% !important;
  width: 27% !important;
  height: 46%;
  position: fixed;
}

.seatingView .editWrap {
  position: absolute;
  top: 32px;
  right: 10px;
}

.seatingView .saveNewName {
  position: absolute;
  left: 10px;
  top: calc(50% - 4px);
  z-index: -1;
  opacity: 0;
}

.seatingView .saveNewName svg {
  width: 11px;
  height: 8px;
  fill: #293b5a;
  display: block;
}

#editbleTableName {
  color: #7a94c2;
  font-size: 0.7059rem;
  z-index: -1;
  opacity: 0;
  border: 1px solid #bfc1c5;
  border-radius: 10px;
  display: block;
  background: #fff;
  min-width: 130px;
  padding-right: 5px;
  transition: all 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

#editbleTableName:focus {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

#editbleTableName:focus+.saveNewName {
  z-index: 1;
  opacity: 1;
}

.seatingView .editName {
  display: inline-block;
  vertical-align: middle;
}

.seatingView .editName svg {
  width: 11px;
  height: 11px;
  display: block;
}

.seatingView .tableCapacity,
.tableAvailability {
  color: #484848;
  font-size: 0.7059rem;
  line-height: 0.7059rem;
}

.seatingView .tableCapacity {
  margin-left: 10px;
}

.seatingView .tableGuestList .scrollerWrap {
  float: right;
  width: 100%;
  /*height:400px;*/
}

.seatingView .tableGuestList .scrollerWrap ul {
  width: 100%;
  min-height: 100%;
}

#addTable {
  min-height: 165px;
}

#addTable .guestName {
  font-size: 0.8235rem;
}

#addTable .guestGroup {
  font-size: 0.6471rem;
}

#addTable .guestTable {
  margin: 0;

  line-height: 13px;
}

#addTable .guestItem {
  padding: 5px;
}

#addTable .guestTable .tableNum,
#addTable .guestTable .tableName,
#addTable .guestTable .i_table {
  display: none;
}

#addTable .guestTable .guestAmount {
  margin: 0;
}

#addTable .guestAmount .i_user {
  width: 8px;
  height: 10px;
}

#addTable .guestEdit {
  margin-left: 5px;
  margin-top: 12px;
}

.seatingView .tableGuestList .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
}

.seatingView .tableGuestList .mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
}

.seatingView .tableGuestList .mCS-dir-rtl:not(.mCS_no_scrollbar)>.mCSB_inside>.mCSB_container {
  margin-right: 16px;
}

.seatingView .statusDropDown li {
  padding: 7px 0;
  cursor: pointer;
}

.seatingView .selectedValue span svg,
.seatingView .statusDropDown li svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  display: inline-block;
  margin-left: 7px;
}

.seatingView .selectedValue span span,
.seatingView .statusDropDown li span {
  vertical-align: middle;
  display: inline-block;
  color: #555555;
  font-size: 0.8235rem;
}

.seatingView .selectedValue {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-radius: 27px;
  border: 1px solid #bfc1c5;
  background: #fff;
  padding: 0 15px;
  color: #fff;
}

.seatingView .statusDropDown {
  z-index: 1;
  background: #fff;
  width: 125px;
  padding: 0 10px;
  border: 1px solid #dddddd;
  position: absolute;
  top: calc(100% + 15px);
  right: 50%;
  transform: translateX(50%);
  display: none;
}

.seatingView .statusDropDown.open {
  display: block;
}

.seatingView .statusDropDown:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #dddddd;
  position: absolute;
  bottom: 100%;
  right: calc(50% - 6px);
}

.seatingView .statusDropDown:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  bottom: 100%;
  right: calc(50% - 4px);
}

.seatingView .secondLevel {
  margin: 0 -7px;
}

.seatingView .secondLevel div[class^="col-"] {
  padding: 0 7px;
}

.seatingView .selectedValue {
  position: relative;
}

.seatingView .selectedValue .i_select {
  width: 16px;
  height: 9px;
  position: absolute;
  top: calc(50% - 4px);
  left: 10px;
  fill: #8798b5;
}

#modal-editGuest {
  max-width: 700px !important;
}

.seatingView .guestCounter {
  width: 100%;
  height: 54px;
  line-height: 52px;
  border-radius: 27px;
  border: 1px solid #bfc1c5;
  float: right;
}

.seatingView .guestCounter span {
  width: 33.3%;
  float: right;
  text-align: center;
  height: 100%;
}

.seatingView .guestCounter span.plus {
  background: #f2f2f2;
  border-radius: 0 27px 27px 0;
}

.seatingView .guestCounter span.minus {
  background: #f2f2f2;
  border-radius: 27px 0 0 27px;
}

.seatingView .guestCounter span svg {
  width: 17px;
  height: 100%;
  fill: #293b5a;
  display: block;
  margin: 0 auto;
}

.seatingView .guestCounter span.value {
  font-size: 1rem;
  color: #434343;
}

.seatingView .inputWrap.submitWrap.deleteBtn button {
  background: #fff;
}

.seatingView .inputWrap.submitWrap.deleteBtn button svg {
  width: 18px;
  height: 19px;
  fill: #7893c1;
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
}

.seatingView .inputWrap.submitWrap.deleteBtn button span {
  color: #7893c1;
  display: inline-block;
  vertical-align: middle;
}

.seatingView .statusBar .active {
  background: #f3f3f3 !important;
  height: 41px;
}

#arrive.active {
  border-radius: 0 27px 27px 0;
}

@media only screen and (max-width: 1450px) {
  .seatingView .statusBtn:not(#all) span {
    display: none;
  }

  .seatingView .statusBtn svg {
    margin: 0;
  }

  .seatingView .guestFilter .col-lg-6 {
    width: 100%;
  }

  .seatingView .guestWrap {
    height: calc(100% - 327px);
  }
}

.not_active {
  opacity: 0.5;
}

.item .active {
  font-weight: bold;
  border-color: red !important;
  border-width: 4px;
}

.overCapacity div:first-child {
  background-color: #fdfaa1 !important;
}

.guestItem.gu-mirror {
  transform: scale(0.2);
}

.yellowHighlight[data-excessive]>div:first-child {
  background: rgb(250, 250, 154) !important;
}

@media only screen and (max-width: 769px) and (max-height: 1025px) {

  .innerBox,
  .innerBox.filterBox {
    padding: 20px 0px 20px 0px;
  }

  .statusBar .active {
    height: 100%;
  }

  .innerBox.filterBox .inputWrap.statusBarWrap {
    width: 600px;
    padding-bottom: 20px;
  }
}
</style>