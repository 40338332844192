<template>
    <Modal ref="addCustomerModal" :title="modalTitle" :large="true">


            <template v-slot:body>


                <div v-for="(contact,index) in contacts">
                    <h5>
                        איש קשר
                        {{ index +1 }}
                    </h5>
                    <CustomerContactFields :contact="contact"></CustomerContactFields>
                </div>


                <button @click="addContact" class="btn btn-secondary btn-sm">
הוסף
                </button>
&nbsp;
                <button @click="removeContact" class="btn btn-danger btn-sm">
הסר
                </button>


                

            </template>

            <template v-slot:buttons>
                <button @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">
                    שמור
                </button>
            </template>


    </Modal>
</template>

<script>

    const DEFAULT_FIRST_CONTACT_TITLE = 1; //first contact is bride, i don't think this is right

    import CustomerContactFields from './CustomerContactFields.vue';
    import { apiRequest } from '../../core/http/axios_api_v2';


    function initialState(){
        return {
                modalTitle: "לקוח חדש - הוספת אנשי קשר:",
                contacts: [
                    {
                        title: DEFAULT_FIRST_CONTACT_TITLE,   

                    }
                ],
            }
    }


    export default {
        name: "AddCustomerModal",
        data(){
            return initialState();
        },
        methods: {
            show(){
                this.$refs.addCustomerModal.show();
            },
            addContact (){

                

                let newContact = {};
                
                if (!this.contacts.length){

                    newContact.title = DEFAULT_FIRST_CONTACT_TITLE;

                } else if (this.contacts.length==1 && this.contacts[0].title==1){
                    
                    newContact.title = 2;

                }

                this.contacts.push(newContact);

            },
            removeContact (){
                this.contacts.pop();
            },
            async save(){

                //this is the actual save
                let customerId = await apiRequest('post','customers',{contacts:this.contacts});
                


                //checks on customerId..

                this.$refs.addCustomerModal.hide();

                this.$emit('savedNewCustomer',customerId);


                //reset component after save new customer, because if we add new customers, let them be new
                Object.assign(this.$data, initialState());



            }
        },
        components: {
            CustomerContactFields
        }

    }

</script>