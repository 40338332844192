import { dateToStr, addDays, subDays } from "../helpers/date-helper.js";
import axiosV2 from "../core/http/axios_api_v2"
import { mapCalendarItems, mapEvents, mapGoogleEvents, mapMeetings, mapQuotes, mapTasks, mapMaybeEvents } from '../services/calendar/item-mapper'

export default {




    //coooooool!
    cache: {},



    hardResetAllCalendars(){

        this.cache = {};
        this.currentMonthPromise = null;
        this.activeCalendarComonents.map(calendar => calendar.updateItems(calendar.dateToShow));
        

    },


    activeCalendarComonents: [],

    registerCalendarComponent(calendarComponent){
        this.activeCalendarComonents.push(calendarComponent);
    },


    addItemToCache(itemDate,itemType,itemData){

        //hard reset because of all the maybe events we could have
        if (itemType=='event'){
            this.hardResetAllCalendars();
            return;
        }


        if (!itemDate)
            return;

        if (typeof itemDate == 'string')
            itemDate = new Date(itemDate);

        let itemForCalendar;
        let cacheKeyForNewItem = this.buildCacheKey(itemDate);

        //if this key is not even cached we have nothing to do
        if (!this.cache[cacheKeyForNewItem])
            return;

        


        //transforming from native item to calendar item
        switch (itemType){
            case 'task':
                itemForCalendar = mapTasks(itemData)
                break;
            case 'meeting':
                itemForCalendar = mapMeetings(itemData)
                break;

            // case 'event': //event doesnt happen here because of hard reset
                // itemForCalendar = mapEvents(itemData)
                // break;

                
                
        }

        
        //checking if already exists, and removing:
        let existingIndex = this.cache[cacheKeyForNewItem].findIndex(item => item.id == itemForCalendar.id);
        if (existingIndex != -1){   //because it can be 0
            this.cache[cacheKeyForNewItem].splice(existingIndex,1);
        }

        //adding item to cache
        this.cache[cacheKeyForNewItem].push(itemForCalendar);
        
        //getting list of calendars to update
        let calendarsToUpdate = this.getCalendarsCurrentlyShowingThisKey(cacheKeyForNewItem);

        //doing the actual updates
        calendarsToUpdate.map(calendar => calendar.updateItems(calendar.dateToShow));


    },


    getCalendarsCurrentlyShowingThisKey(cacheKey){

        let calendarsToUpdate = [];

        for (let i=0; i<this.activeCalendarComonents.length; i++){

            if (this.activeCalendarComonents[i].currentCacheKey == cacheKey)

                calendarsToUpdate.push(this.activeCalendarComonents[i]);
            
        }

        return calendarsToUpdate;

    },



    //on load we might have 2 calendars initiating and wanting to pull todays data at same time
    currentMonthPromise: null, 




    addToCache(key,value){
        this.cache[key] = value;
    },

    getFromCache(key){
        return this.cache[key];
    },


    thisIsTheCacheKeyForCurrentMonth(cacheKey){

        return cacheKey == this.buildCacheKey(new Date())

    },

    buildCacheKey(d){
        const utc = new Date(d.toISOString())
        const start_date = this.getMonthStartDate(utc);
        const end_date = this.getMonthEndDate(utc);
        return `${start_date} - ${end_date}`;
    },


    getMonthStartDate(utc){
        return dateToStr(subDays(utc, utc.getDate() + 7), 'yyyy-mm-dd')
    },
    getMonthEndDate(utc){
        return dateToStr(addDays(utc, 31 - utc.getDate() + 7), 'yyyy-mm-dd')
    },


    //we play here with +7 to show also the weeks before and after. it works well
    getItemsInMonth(d) {    

        const utc = new Date(d.toISOString())
        const start_date = this.getMonthStartDate(utc);
        const end_date = this.getMonthEndDate(utc);
        const cacheKey = this.buildCacheKey(d);


        //check if we already promised current month data
        if (this.thisIsTheCacheKeyForCurrentMonth(cacheKey) && this.currentMonthPromise)
            return this.currentMonthPromise;


        //check if we have this month in cache, we return a promise because callers of this function expect a promise
        if (this.getFromCache(cacheKey)){
            console.log(`Calendar cache hit: ${cacheKey}`);
            return new Promise((resolve)=>resolve(this.getFromCache(cacheKey)));
        }
            

        console.log(`Requesting calendar from server: ${start_date} - ${end_date}`);

        let axiosPromise = axiosV2(`calendar?start_date=${start_date}&end_date=${end_date}`).then((response) => {

            let items = [
                ...response.data.events.map(mapEvents),
                ...response.data.meetings.map(mapMeetings),
                ...response.data.tasks.map(mapTasks),
                ...response.data.googleEvents.map(mapGoogleEvents),
                // ...response.data.maybeEvents.map(mapMaybeEvents),
            ];

            this.addToCache(cacheKey,items);

            return items;
        })


        if (this.thisIsTheCacheKeyForCurrentMonth(cacheKey))
            this.currentMonthPromise = axiosPromise;


        return axiosPromise;
    }






}