import axiosV2, { apiRequest, apiPost } from '../core/http/axios_api_v2';

export default {

    

    validateMeeting(component, meeting) {


        let meetingErrors = {};



        meetingErrors.title = !meeting.title ? 'שדה חובה' : null;
        meetingErrors.date = !meeting.date ? 'שדה חובה' : null;
        // meetingErrors.time = !meeting.time ? 'שדה חובה' : null;

        component.$set(meeting, 'errors', meetingErrors);

        //this checks if there is at least one field with an error
        let meetingIsValid = !Object.values(meetingErrors).some(value => value);

        if (!meetingIsValid)
            planup.$globals.notifyFormError();

        return meetingIsValid;
    },



    //helper functions
    ///////////////
    generateLocalEmptyMeeting(customer,date,time){
        return {
            isNotifyByMail:true,
            // notifyMailAddress:planup.$handlers.customerHandler.getCustomerEmail(customer),
            notifyMailAddress:[],
            customer,
            date,
            crewMembers: [],
            id: null,
            time,
            endTime: null,
        }
    },


    buildMeetingSummaryForTimeline(meeting){

        let meetingSummaryText = 'בתאריך ' + planup.$globals.niceDate(meeting.date);

        if (meeting.location){
            meetingSummaryText += ' ב' + meeting.location;
        }

        if (meeting.crewMembers && meeting.crewMembers.length){
            meetingSummaryText += ' עם ';
            for (let i=0; i<meeting.crewMembers.length;i++){
                meetingSummaryText += meeting.crewMembers[i].name + ', ';
            }
            meetingSummaryText = meetingSummaryText.slice(0,-2);
        }

        return meetingSummaryText;

    },




    // getting functions
    //////////////////////


    async getMeeting(meetingId){
            
            return axiosV2(`/meeting/${meetingId}`).then(
    
                response => response.data
                
            );
    },

    async getMeetings(customerId){


        if (customerId){

            return axiosV2(`/meeting/getMeetings/${customerId}`).then(

                response => response.data
                
            );
        }


        return axiosV2("/meeting/getMeetings").then(

            response => response.data
            
        );
    },


    



    //saving functions
    //////////////////

    // this function is good for general creation and initial changes,
    // saving THE WHOLE MEETING,
    // later we update specific things in the meeting no?
    async saveMeeting(meetingData,callback){

        if (!meetingData.date)
            meetingData.date = new Date();

        setNoon(meetingData.date);


        

        return apiPost('meeting',meetingData).then(
            savedMeeting => {
                
                meetingData.id = savedMeeting.id;
            
                planup.$handlers.calendarHandler.addItemToCache(meetingData.date,'meeting',meetingData);

                if (typeof callback == 'function')
                    callback();

                return savedMeeting.id;
            }
        );
    },





    //navigation functions
    gotoAddNewMeeting(customer,date){
        if (date){
            planup.$router.push(`/customer/${customer.id}?newMeeting=${date.getTime()}`)
        } else {
            planup.$router.push(`/customer/${customer.id}?newMeeting`)
        }
        
    },

    deleteMeeting(meetingId){
        return apiPost(`meeting/delete/${meetingId}`).then(


            
            response => {

                response.data
            }
            
        )
    },

    gotoAddNewMeetingFromSearch(searchResult,date,time){
        
        if (date){
            
            let url = `/customer/${searchResult.customerId}?newMeeting=${date.getTime()}`;

            if (time)
                url += '&timeForMeeting=' + time;

            planup.$router.push(url)
        } else {
            planup.$router.push(`/customer/${searchResult.customerId}?newMeeting`)
        }
        
    },


    calendarTooltip(eventId) {
        return axiosV2.get(`/meeting/tooltip/${eventId}`).then(response => {

            let html = `
            
                <div style="text-align: center;color:rgb(119, 119, 119)">
                    ${planup.$globals.niceDate(response.data.date)}

                    ${response.data.time ? ' &nbsp; ' + response.data.time : '' }

                </div>
                <br>

                <div style="font-weight:bold;">

                    ${response.data.title}

                </div>

                <br>
            
            `;






            for (let i = 0; i < response.data?.customer?.contacts?.length; i++) {

                html += `<img class='eventTooltipContactImage' src="${planup.$handlers.customerHandler.getContactImage(response.data.customer.contacts[i])}"> ${response.data.customer.contacts[i].firstName}&nbsp;&nbsp;`

            }

            html += '<br>';
            html += '<br>';


            if (response.data?.meetingType?.name)
                html += 'סוג פגישה: ' + response.data.meetingType.name + '<br>'


            if (response.data?.place?.name)
                html += 'מקום: ' + response.data.place.name + '<br>'


            if (response.data?.crewMembers && response.data.crewMembers.length)
                html += 'צוות : ' + response.data.crewMembers.map(member => member.name).join(', ') + '<br>'


            return html;

        })
    },



}