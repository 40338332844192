import axiosV2, { apiPost } from '../core/http/axios_api_v2';

export default {

    getLatest(limit,customerId) {
        return axiosV2(`planup-multi-messages?limit=${limit}&customerId=${customerId}`).then(response => response.data);
    },



    loadConversation(msgGroup) {

        if (!msgGroup)
            return new Promise(x => []);

        return axiosV2(`planup-multi-messages/loadConversation/${msgGroup}`).then(response => response.data);

    },


    
}