<template>
  <div>
    <div class="innerMainContent">
      <PageHeader icon="i_user" :title="$t('addUser')" :breadcrumbs="[
        { name: $t('menu_users'), route: '/users' },
        { name: $t('addUser'), route: '' },
      ]">
      </PageHeader>

      <div class="innerBox">
        <form action="" id="editProfile" class="formWrap" @submit.prevent="submitForm">
          <div class="row">
            <!-- first column -->
            <div class="col-xs-12 col-md-6">
              <div class="row">
                <div class="col-xs-12">
                  <h2>{{ $t("generalDetails") }}</h2>
                </div>
              </div>

              

              <!-- <div class="row" v-if="$store.state.user.type == 1">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="type">*{{ $t("type") }}</label>
                    <select v-model="user.type" required>
                      <option v-for="(value, name, index) in roles()" :key="index" :value="value">
                        {{ name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div> -->

              <div class="row" v-if="$store.state.user.type == 1 && userToAdd.type == 5">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="type">*{{ $t("bizCategory") }}</label>
                    <select v-model="userToAdd.bizCategory">
                      <option v-for="(category) in this.bizCategories" :key="category.id" :value="category.id">
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>


              <div class="row" v-if="$store.state.user.type == 1 && userToAdd.type == 5">
                <div class="col-sm-6">
                  <div>
                    <label for="type">*{{ $t("bizServices") }}</label>
                    

                    <ul>
                    <li v-for="service in bizServices">

                      <input type="checkbox" :value="service.id" :id="'service' + service.id" v-model="userToAdd.bizServices">
                      <label :for="'service' + service.id" style="cursor:pointer">
                        {{ service.name }}
                      </label> 



                    </li>
                  </ul>



                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="fullName">*{{ $t("fullName") }}</label>
                    <input type="text" maxlength="50" id="fullName" name="fullName" v-model="userToAdd.name" required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="phone">{{ $t("phone") }}
                      <span>({{ $t("mobile") }})</span></label>
                    <input type="tel" id="phone" name="phone" v-model="userToAdd.phone" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="email">*{{ $t("email") }}</label>
                    <input type="email" id="email" name="email" v-model="userToAdd.email" required />
                  </div>
                </div>
              </div>
              <div v-if="userToAdd.type != 5">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="phone">{{ $t("role") }} </label>
                      <input type="text" id="roleName" name="roleName" v-model="userToAdd.attrs.roleName" />
                    </div>
                  </div>
                </div>
                <FormInput type="checkbox" label="סוכן מכירות" display="horizontal" v-model="userToAdd.attrs.isSalesman" v-if="this.$store.state.user.type === 5" />
                <div class="row" v-if="this.$store.state.user.type == 1">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="domain">*{{ $t("app_domain") }}</label>
                      <input type="text" maxlength="50" id="domain" name="domain" v-model="userToAdd.app.domain" required />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="this.$store.state.user.type == 1">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="appName">*{{ $t("app_name") }}</label>
                      <input type="text" maxlength="50" id="appName" name="appName" v-model="userToAdd.app.name" required />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- second column -->
            <div class="col-xs-12 col-md-6">
              <div class="row">
                <div class="col-xs-12">
                  <h2>{{ $t("passwordUpdate") }}</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="password">*{{ $t("password") }}</label>
                    <password :badge="false" :secureLength="6" :required="true" :placeholder="null" id="password" name="password" v-model="userToAdd.password"></password>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap submitWrap">
                    <button class="submit">{{ $t("save") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
import PageHeader from "../Common/PageHeader.vue";
import ROLES_ENUM from "../../core/auth/roles.enum.js";
import Multiselect from "vue-multiselect";

export default {
  name: "AddUser",
  watch: {
      "userToAdd.bizCategory":{
        handler(newValue){

          let category = this.bizCategories.find(x => x.id==newValue)
          this.userToAdd.bizServices = category.services;
          
        }
      }
  },
  data() {
    return {
      userToAdd: { 
        type: this.$store.state.user.type == ROLES_ENUM.Admin ? ROLES_ENUM.HallManager : ROLES_ENUM.HallManagerChild,
        bizServices: [],
        attrs: {}, 
        app: {} ,
      }, 
      bizCategories: null,
      bizServices: null
    };
  },
  components: {
    Password,
    PageHeader,
    Multiselect
  },
  mounted() { },

  beforeMount() {


    this.$store
      .dispatch("restGet", { 'route': 'biz-categories' })
      .then((response) => {
        this.bizCategories = response;
      })


    this.$store
      .dispatch("restGet", { 'route': 'biz-services' })
      .then((response) => {
        this.bizServices = response;
      })


   },
  methods: {
    roles() {
      return { ריסלר: ROLES_ENUM.Resseler, ספק: ROLES_ENUM.HallManager };
    },
    submitForm() {
      const userType = this.$store.state.user.type;
      if (userType == ROLES_ENUM.Admin) {
        this.createResselerUser();
      } else if (userType == ROLES_ENUM.HallManager) {
        this.createNormalUser();
      }
    },

    createResselerUser() {
      if (
        typeof this.$data.userToAdd.password !== "undefined" &&
        this.$data.userToAdd.password.length < 6
      ) {
        this.AlertError(this.$t("passwordLength"));
        return false;
      }
      this.$store
        .dispatch("restPost", {
          route: "users/resseler",
          data: this.$data.userToAdd,
        })
        .then((response) => {
          this.AlertSuccess(this.$t("userAdded"))
          // .then((user) => {
          //   this.$router.push("/users/");
          // });
        })
        .catch((response) => {
          this.AlertError();
          console.error(response)
        });
    },
    createNormalUser() {
      this.$store
        .dispatch("addUser", this.$data.userToAdd)
        .then((response) => {
          this.$swal({
            confirmButtonText: this.$t("okButton"),
            type: "success",
            title: this.$t("userAdded"),
            timer: 2000,
          })
            .then((user) => {
              this.$router.push("/users/");
            })
            .catch(this.$swal.noop);
        })
        .catch((response) => {
          this.AlertError();
          console.error(response)
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.editEventActions {
  float: left;
  margin-top: 6px;
}

.editEventActions .backBtn span {
  color: #7c97c5;
  font-weight: 700;
  font-size: 1.235rem;
  display: inline-block;
  vertical-align: middle;
}

.editEventActions .backBtn svg {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 18px;
  fill: #293b5a;
  margin-right: 5px;
}

.editEventActions .deleteBtn {
  width: 218px;
  height: 54px;
  background: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 27px;
  margin-left: 30px;
}

.editEventActions .deleteBtn span {
  color: #293b5a;
  font-weight: 700;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.editEventActions .deleteBtn svg {
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 23px;
  fill: #849dc8;
  margin-left: 10px;
}

.innerBox {
  padding: 30px;
}

.h1Wrap svg.i_profile {
  width: 26px;
  height: 30px;
}
</style>
