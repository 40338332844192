
import Users from '@/components/Users/Users'
import Profile from '@/components/Users/Profile'
import UserEdit from '@/components/Users/UserEdit'
import AddUser from '@/components/Users/AddUser'
import UserServices from '@/components/Users/UserServices'
import UserPermissions from '@/components/Users/UserPermissions'
import Role from '../../core/auth/roles.enum';

export default [
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            requiresAuth: true,
            userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
            matchHeight: true
        }
    },
    {
        path: '/users/add',
        name: 'addUser',
        component: AddUser,
        meta: {
            requiresAuth: true,
            havePermission: 'users.create',
            matchHeight: true
        }
    },
    {
        path: '/users/:id/permissions',
        name: 'userPermissons',
        component: UserPermissions,
        meta: {
            requiresAuth: true,
            userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
            matchHeight: true
        }
    },
        {
        path: '/users/services/:id',
        name: 'userServices',
        component: UserServices,
        meta: {
            requiresAuth: true,
            userInRole: [Role.Admin],
            matchHeight: true
        }
    },
    {
        path: '/users/:id',
        name: 'editUser',
        component: UserEdit,
        meta: {
            requiresAuth: true,
            userInRole: [Role.Admin,Role.Resseler,Role.HallManager],
            matchHeight: true
        }
    },

    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true,
            matchHeight: true
        }
    },
   

  

   
]