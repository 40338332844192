
<!-- MAKE SURE TO ADD data-bs-dismiss="modal" IN THE BUTTONS SLOT IF THEY NEED TO CLOSE THE MODAL-->
<!-- THIS REALLY IS JUST TAKING DEANS MODAL AND REPLACING IT TO A WRAPPER OF BOOTSTRAP FROM UNIVERSAL THEME -->



<template>
      <div class="modal fade" :id="'modal_'+_uid">
        <div class="modal-dialog" :style="{width:customWidth+'px'}" :class="{'modal-lg':large, 'modal-xl':xlarge,'modal-dialog-centered':center,'swal2-show':center}">
          <div class="modal-content">
            <div class="modal-header"  v-if="!hideHeader">
              <h5 class="modal-title">
{{ title }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" v-if="!noClose">
                <span aria-hidden="true"></span>
              </button>
            </div>
            <div class="modal-body" style="text-align: right;">
              <slot name="body"></slot>
            </div>
            <div class="modal-footer" v-if="!hideFooter">
              <slot name="buttons"></slot>
            </div>
          </div>
        </div>
      </div>
</template>

<script>

export default {
  name: "Modal",

  props: ['title','large','hideFooter','hideHeader','xlarge','center','noClose','customWidth'],
  data() {
    return {
      modalElement: null,
    }
  },
  mounted() {


    if (this.noClose) {

      this.modal = new bootstrap.Modal(document.getElementById('modal_'+this._uid),{backdrop: 'static', keyboard: false});

    } else {

      this.modal = new bootstrap.Modal(document.getElementById('modal_'+this._uid));

    }

    
  },
  methods: {

    hide() {
      this.modal.hide();
    },
    show() {
      this.modal.show();
    },

  },
};
</script>
<style>
</style>
