<template>
    <Modal ref="actionsForCustomer" :title="modalTitle" :hideFooter="true">


      <template v-slot:body>

        <div class="row">


          <div class="col-4">
            <button class="btn btn-primary" @click="addMeeting" data-bs-dismiss="modal">
לקבוע פגישה
            </button>
          </div>


            <div class="col-4">
              <button class="btn btn-primary col-4" @click="addEvent" data-bs-dismiss="modal">
לבדוק / לקבוע אירוע
            </button>
            </div>

            <div class="col-4">
              <button class="btn btn-primary col-4" @click="editDetails" data-bs-dismiss="modal">
לעדכן פרטים
              </button>
            </div>



        </div>


      </template>
            
    </Modal>
</template>

<script>
import axiosV2, { apiRequest } from '../../core/http/axios_api_v2';   

    export default {
        name: "ActionsForCustomerModal",
        data(){
            return {
                modalTitle: '',
                customer: {},
                selectedDate: null
            }
        },
        methods: {

            addEvent(){
              this.$router.push(`/customer/${this.customer.id}?addEvent`);
            },

            addMeeting(){
              this.$router.push('/meeting/add/'+this.customer.id)
            },

            editDetails(){
              
            },

            async show(customerId,selectedDate){

                this.customer = (await axiosV2('customers/'+customerId)).data;
                this.selectedDate = selectedDate;


                if (this.selectedDate){

                  this.modalTitle = 'מה תרצו לעשות עם לקוח ' + this.customer.id +'בתאריך '+ selectedDate +'?';

                } else {

                  this.modalTitle = 'מה תרצו לעשות עם לקוח ' + this.customer.id +'?';
                }

              
                this.$refs.actionsForCustomer.show();    

            },
            hide(){
                this.$refs.actionsForCustomer.hide();
            }
        },
        components: {
            
        }

    }

</script>