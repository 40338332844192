import axiosV2, { apiPost } from '../core/http/axios_api_v2';

export default {

    save(messageTemplate) {
        return apiPost('message-templates', messageTemplate).then(messageTemplateFromServer => messageTemplate.id = messageTemplateFromServer.id)
    },

    generateLocalMessageTemplate() {
        return {
            title: '',
            text: ''
        }
    },

    deleteMessageTemplate(messageTemplate) {


        planup.$swal({
            title: ` האם למחוק את התבנית "${messageTemplate.title}"?`,
            confirmButtonText: 'כן',
            cancelButtonText: 'לא',
            confirmButtonColor: '#ff5370',
            dangerMode: true,
            showCancelButton: true,
        }).then((confirm) => {
            if (confirm) {
                let index = planup.$store.state.messageTemplates.findIndex(mt => mt.id == messageTemplate.id)
                planup.$store.state.messageTemplates.splice(index, 1)

                if (messageTemplate.id)
                    return apiPost(`message-templates/delete/${messageTemplate.id}`)
            }
        });




    }

}